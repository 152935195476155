import { Injectable } from '@angular/core'
import { AdditionFragment, DailyMenuItemFragment } from '@app-graphql/api-schema'
import { RestaurantsService } from '@app/services/restaurants/restaurants.service'
import { Storage } from '@ionic/storage'
import {
    dissoc,
    groupBy,
    isEmpty,
    isNil,
    keys,
    map as RMap,
    pipe,
    pluck,
    prop,
    sortBy,
    toPairs,
} from 'ramda'
import { BehaviorSubject, combineLatest, filter, first, Observable, of } from 'rxjs'
import { distinctUntilChanged, map, switchMap, takeWhile } from 'rxjs/operators'
import { isFuture } from 'date-fns'
import { AnyDate, ensureParsed, ensureSerialised, formatDateTime, parseDateTime } from '@lib/date-time/date-time.lib'
import Bugsnag from '@bugsnag/js'
import { CartResolverService } from '@app/services/cart/cart-resolver.service'
import { distinctUntilChangedEquals, shareReplayOne } from '@lib/rxjs/rxjs.lib'
import {
    Cart,
    CartItem,
    CartItems,
    CartKeyData,
    CartListing,
    CartResolveResult,
    Carts,
} from './cart.types'
import type { RequiresInitialization } from '@app/types/framework.types'
import { DeepMutable } from '@app/types/common.types'

@Injectable({
    providedIn: 'root',
})
export class CartService implements RequiresInitialization {

    private readonly storageKey = 'cart:carts'

    private readonly cartsSubject = new BehaviorSubject<Carts>({})

    constructor(
        private readonly storage: Storage,
        private readonly restaurantsService: RestaurantsService,
        private readonly cartResolver: CartResolverService,
    ) {
    }

    // This also makes the cartsSubject emit for the first time:
    public async initialize(): Promise<void> {
        await this.pruneExpiredCarts()
    }

    public getKeys(): string[] {
        return keys(this.cartsSubject.getValue())
    }

    public getCarts(): Carts {
        return this.cartsSubject.getValue()
    }

    public hasCarts(): boolean {
        return ! isEmpty(this.getCarts())
    }

    public getCart(key: string): Cart | null {
        return this.getCarts()[key] ?? null
    }

    public hasCartItem(cartKey: string, cartItemKey: string): boolean {
        const cart = this.getCart(cartKey)
        return cart !== null && cart.items.hasOwnProperty(cartItemKey)
    }

    // ------------------------------------------------------------------------------
    //      cart observable methods
    // ------------------------------------------------------------------------------

    /**
     * Watches the cart for given key. The returned observable will emit the cart changes as long as
     * it exists, and will complete as soon as the cart is removed. If the cart does not exist at
     * call-time, the returned observable completes immediately.
     */
    public watchCart(cartKey: string): Observable<CartResolveResult> {
        return this.cartsSubject.pipe(
            map(prop(cartKey)),
            distinctUntilChangedEquals(),
            takeWhile((cart): cart is Cart => ! isNil(cart)),
            switchMap((cart) => this.cartResolver.resolve(cart)),
        )
    }

    public watchItemCount(): Observable<number> {
        return this.cartsSubject.pipe(
            map((carts) => {
                return Object.values(carts).reduce((sum, { items }) => {
                    return sum + Object.values(items).reduce((itemsSum, item) => {
                        return itemsSum + item.quantity
                    }, 0)
                }, 0)
            }),
            distinctUntilChanged(),
        )
    }

    /**
     * Watches the set of current cart keys.
     */
    public watchCartKeys(): Observable<readonly string[]> {
        return this.cartsSubject.pipe(
            map(keys),
            distinctUntilChangedEquals(),
        )
    }

    /**
     * Returns a stream that emits `undefined` once as soon as a cart by given key
     * does no longer exist in the carts record, and then completes. That might be
     * immediately at subscription time if the cart does not exist then. This can
     * be used to kill other streams using `takeUntil()` when they
     * are only relevant for the cart by given key.
     */
    public watchCartRemoval(key: string): Observable<void> {
        return this.cartsSubject.pipe(
            filter((carts) => ! carts.hasOwnProperty(key)),
            first(),
            map(() => undefined),
        )
    }

    // ------------------------------------------------------------------------------
    //      Cart mutation methods
    // ------------------------------------------------------------------------------

    public async addMenuItem(
        menuItem: DailyMenuItemFragment,
        transferDate: AnyDate,
        additions: readonly AdditionFragment[] = [],
        quantity: number = 1,
    ): Promise<void> {
        const restaurantId = menuItem.context.restaurantID
        const cartKey = this.getCartStorageKey(restaurantId, transferDate)
        const cartItemKey = this.getCartItemStorageKey(menuItem.sources.menuEntryOccurrence, pluck('id', additions))

        const carts = await this.getStoredCarts()

        carts[cartKey] ??= await this.createEmptyCart(restaurantId, transferDate)
        carts[cartKey]!.items[cartItemKey] ??= this.createCartItem(cartItemKey, menuItem, additions, 0)
        carts[cartKey]!.items[cartItemKey]!.quantity += quantity

        await this.submitCarts(carts)
    }

    public async setQuantity(cartKey: string, cartItemKey: string, quantity: number): Promise<void> {
        if (! this.hasCartItem(cartKey, cartItemKey)) {
            throw new Error('Cannot set quantity of non-existing cart item.')
        }

        if (quantity <= 0) {
            return this.removeCartItem(cartKey, cartItemKey)
        }

        const carts = await this.getStoredCarts()
        carts[cartKey]!.items[cartItemKey]!.quantity = quantity
        await this.submitCarts(carts)
    }

    /**
     * Removes one cart item by {@link CartItem.key its ID} - NOT its `menuItemId`!
     */
    public async removeCartItem(targetCartKey: string, targetItemKey: string): Promise<void> {
        const prevCarts: Carts = await this.getStoredCarts()
        const nextCarts: Carts = {}

        for (const [cartKey, cartValue] of toPairs(prevCarts)) {
            if (cartKey !== targetCartKey) {
                nextCarts[cartKey] = cartValue
                continue
            }

            const nextItems = toPairs(cartValue.items).reduce<CartItems>((acc, [itemKey, itemValue]) => {
                return itemKey === targetItemKey
                    ? acc
                    : { ...acc, [itemKey]: itemValue }
            }, {})

            if (! isEmpty(nextItems)) {
                nextCarts[cartKey] = { ...cartValue, items: nextItems }
            }
        }

        await this.submitCarts(nextCarts)
    }

    public async clearAllCarts(): Promise<void> {
        await this.submitCarts({})
    }

    public async removeCart(key: string): Promise<void> {
        const prevCarts: Carts = await this.getStoredCarts()
        const nextCarts: Carts = dissoc(key, prevCarts)
        await this.submitCarts(nextCarts)
    }

    // ------------------------------------------------------------------------------
    //      Utility methods
    // ------------------------------------------------------------------------------

    /**
     * Counts the total quantity by which the given menu-item is present in a basket for the given transfer-date.
     */
    public countMenuItems(item: DailyMenuItemFragment, transferDate: Date): number {
        const key = this.getCartStorageKey(item.context.restaurantID, transferDate)
        const cart = this.getCart(key)

        if (isNil(cart)) {
            return 0
        }

        return Object
            .values(cart.items)
            .reduce((sum, { menuEntryOccurrenceId, quantity }) => {
                return menuEntryOccurrenceId === item.sources.menuEntryOccurrence
                    ? sum + quantity
                    : sum
            }, 0)
    }

    public parseCartStorageKey(this: unknown, key: string): CartKeyData {
        const matches = key.match(/^restaurant:(\d+)\|transferDate:(\d{4}-\d{2}-\d{2})/)

        if (! matches) {
            throw new Error(`Failed to parse cart storage key '${key}'`)
        }

        return {
            restaurantId: matches[1],
            transferDate: matches[2],
            serializedKey: key,
        }
    }

    // ------------------------------------------------------------------------------
    //      Cart listing methods
    // ------------------------------------------------------------------------------

    public createListing<T>(transformer: (cartKey: string) => Observable<T>): CartListing.Listing$<T> {
        type TransferDateString = string

        return this.watchCartKeys().pipe(
            /* eslint-disable @typescript-eslint/indent */ // this is a bug in typescript-eslint
            map(pipe<
                [readonly string[]],
                CartKeyData[],
                CartKeyData[],
                Record<TransferDateString, CartKeyData[]>,
                [TransferDateString, CartKeyData[]][],
                CartListing.ListedDay<T>[]
            >(
                RMap(this.parseCartStorageKey),
                sortBy(prop('transferDate')),
                groupBy(prop('transferDate')),
                toPairs,
                RMap(([date, parsedKeys]) => ({
                    date,
                    carts: parsedKeys.map(({ serializedKey: key }) => ({
                        key,
                        cart$: transformer(key),
                    })),
                })),
            )),
            shareReplayOne(),
        )
    }

    public flattenListing<T>(listing$: CartListing.Listing$<T>): Observable<readonly T[]> {
        return listing$.pipe(
            switchMap((listing) => isEmpty(listing) ? of([]) : combineLatest(
                listing.reduce<Observable<T>[]>((acc, day) => [
                    ...acc,
                    ...day.carts.map((cart) => cart.cart$),
                ], []),
            )),
        )
    }

    // ------------------------------------------------------------------------------
    //      Private implementation methods
    // ------------------------------------------------------------------------------

    /**
     * Create a cart-item object from the given menu-item, an optional addition,
     * and an optional user comment.
     */
    private createCartItem(
        key: string,
        menuItem: DailyMenuItemFragment,
        additions: readonly AdditionFragment[] = [],
        quantity: number = 1,
    ): DeepMutable<CartItem> {
        return {
            TAG: 'CartItem',
            key,
            menuEntryOccurrenceId: menuItem.sources.menuEntryOccurrence,
            name: menuItem.name,
            imageUrl: menuItem.images?.square150,
            type: menuItem.type,
            price: menuItem.price,
            quantity,
            additions: additions.map((addition) => ({
                id: addition.id,
                name: addition.name,
                price: addition.price,
            })),
        }
    }

    /**
     * Creates an empty cart (without items) object for the given restaurant ID and transfer-date.
     */
    private async createEmptyCart(restaurantId: string, transferDate: AnyDate): Promise<DeepMutable<Cart>> {
        const restaurant = await this.restaurantsService.getRestaurantById(restaurantId)
        const orderDeadline = await this.restaurantsService.orderDeadline(
            restaurant,
            ensureParsed(transferDate, 'date', true),
        )

        if (isNil(orderDeadline)) {
            throw new Error(
                `Cannot create cart for restaurant #${restaurantId} on ${transferDate}; order-deadline passed.`,
            )
        }

        return {
            key: this.getCartStorageKey(restaurantId, transferDate),
            orderDeadline: formatDateTime(orderDeadline),
            orderDeadlineMarginMinutes: restaurant.orderDeadlineMarginMinutes,
            restaurantId,
            restaurantName: restaurant.name,
            restaurantType: restaurant.type,
            restaurantHasTimeslots: restaurant.hasTimeslots,
            restaurantOrderStrategy: this.restaurantsService.resolveOrderStrategyValue(restaurant),
            transferDate: ensureSerialised(transferDate, 'date'),
            items: {},
        }
    }

    /**
     * Loads the currently stored carts from the local data storage.
     */
    private async getStoredCarts(): Promise<DeepMutable<Carts>> {
        const carts = await this.storage.get(this.storageKey)
        return carts ?? {}
    }

    /**
     * Saves the given carts to the local data storage, and then emits the given carts through
     * the {@link carts$ observable stream}. **WARNING**: this overrides all existing data.
     */
    private async submitCarts<T extends Carts>(carts: T): Promise<T> {
        await this.storage.set(this.storageKey, carts)
        this.cartsSubject.next(carts)
        return carts
    }

    /**
     * Removes all carts from the persisted storage that are for any day before today.
     * The persisted storage is first updated, and the cleaned carts record is
     * then emitted through the {@link carts$ observable stream}.
     */
    private async pruneExpiredCarts(): Promise<Carts> {
        const prevCarts: Carts = await this.getStoredCarts()
        const nextCarts: Carts = {}

        for (const [key, cart] of toPairs(prevCarts)) {
            try {
                const deadline = parseDateTime(cart.orderDeadline, true)
                if (isFuture(deadline)) {
                    nextCarts[key] = cart
                }
            } catch (error: any) {
                Bugsnag.notify(error)
            }
        }

        await this.submitCarts(nextCarts)
        return nextCarts
    }

    /**
     * Get the storage key for a cart of given restaurant-ID and transfer-date.
     *
     * _WARNING: do not change this unless absolutely necessary. Changing this will
     * be a breaking change that breaks the UI (adding / deleting items) when updating
     * the app while having stored carts._
     */
    private getCartStorageKey(this: unknown, restaurantId: string, transferDate: AnyDate): string {
        return `restaurant:${restaurantId}|transferDate:${ensureSerialised(transferDate, 'date')}`
    }

    private getCartItemStorageKey(menuEntryOccurrenceId: string, additionIds: readonly string[] = []): string {
        const sortedAdditionIds = [...additionIds].sort().join(',')
        return `menuEntryOccurrence:${menuEntryOccurrenceId}|additions:${sortedAdditionIds}`
    }
}
