import { AuthState } from '@lib/auth/auth.lib'

export interface AuthStateStore {
    getAuthState(): AuthState | null
    submitAuthState(state: AuthState): Promise<void>
    hasAuthState(): boolean
    clearAuthState(): Promise<void>
}

export enum AuthStorageKey {
    ACCESS_TOKEN = 'auth:accessToken',
    TOKEN_TYPE = 'auth:tokenType',
    REFRESH_TOKEN = 'auth:refreshToken',
    EXPIRES_AT = 'auth:expiresAt',
}
