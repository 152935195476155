import { AbstractDeepLink, DeeplinkHandlerArgs } from '@app/services/deeplinks/links/AbstractDeepLink'

export class OrderPickupDeeplink extends AbstractDeepLink {
    public readonly pathPattern: string = '/pickup/:restaurantId'
    public readonly alias: string = 'orderPickup'

    public async handler({ $event, router }: DeeplinkHandlerArgs): Promise<void> {
        const { restaurantId } = $event.$args
        await router.navigateByUrl(`/pickup/restaurant/${restaurantId}`)
    }
}
