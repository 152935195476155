import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { ClientThemeFragment } from '@app-graphql/api-schema'
import { distinctUntilChangedEquals, shareReplayOne } from '@lib/rxjs/rxjs.lib'
import { AuthService } from '@app/services/auth/auth.service'
import type { RequiresInitialization } from '@app/types/framework.types'
import { Colord, colord } from 'colord'
import { isNil, path } from 'ramda'
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators'
import { BehaviorSubject, of, ReplaySubject } from 'rxjs'
import { ContextualColorsMap, Theme, ThemeColors } from '@app/services/theming/theming.types'
import { Nil } from '@app/types/common.types'
import { Storage } from '@ionic/storage'
import { isBoolean } from '@lib/assertions/assertions.lib'

interface ColorMap {
    /**
     * The name by which to qualify CSS variables for this color group.
     */
    readonly cssName: string
    /**
     * The key name of a {@link ThemeColors} object to use for the **main color** binding.
     */
    readonly baseThemeKey: keyof ThemeColors
    /**
     * The key name of a {@link ThemeColors} object to use for the **contrast color** binding.
     */
    readonly contrastThemeKey: keyof ThemeColors
}

@Injectable({
    providedIn: 'root',
})
export class ThemingService implements RequiresInitialization {

    private readonly highContrastEnabledSubject = new ReplaySubject<boolean>()
    public readonly highContrastEnabled$ = this.highContrastEnabledSubject.pipe(
        distinctUntilChanged(),
        shareReplayOne(),
    )

    private readonly contextualColorsSubject = new BehaviorSubject<ContextualColorsMap>({})
    public readonly contextualColors$ = this.contextualColorsSubject.pipe(
        distinctUntilChangedEquals(),
        shareReplayOne(),
    )

    public readonly clientLogo$ = this.authService.user$.pipe(
        map((user) => user?.client.logo ?? null),
    )

    private readonly highContrastTheme: Theme = {
        colorPrimary: '#1f5044',
        colorPrimaryContrast: '#ffffff',
        colorSecondary: '#000000',
        colorSecondaryContrast: '#ffffff',
        colorTertiary: '#000000',
        colorTertiaryContrast: '#ffffff',
        colorTextDark: '#000000',
        colorTextLight: '#ffffff',
        colorSuccess: '#00a94f',
        colorSuccessContrast: '#ffffff',
        colorWarning: '#e88b00',
        colorWarningContrast: '#000000',
        colorError: '#d90041',
        colorErrorContrast: '#ffffff',
        contextualColors: [],
    }

    private readonly colorMaps: readonly ColorMap[] = [
        {
            cssName: 'primary',
            baseThemeKey: 'colorPrimary',
            contrastThemeKey: 'colorPrimaryContrast',
        },
        {
            cssName: 'secondary',
            baseThemeKey: 'colorSecondary',
            contrastThemeKey: 'colorSecondaryContrast',
        },
        {
            cssName: 'tertiary',
            baseThemeKey: 'colorTertiary',
            contrastThemeKey: 'colorTertiaryContrast',
        },
        {
            cssName: 'dark',
            baseThemeKey: 'colorTextDark',
            contrastThemeKey: 'colorTextLight',
        },
        {
            cssName: 'light',
            baseThemeKey: 'colorTextLight',
            contrastThemeKey: 'colorTextDark',
        },
        {
            cssName: 'success',
            baseThemeKey: 'colorSuccess',
            contrastThemeKey: 'colorSuccessContrast',
        },
        {
            cssName: 'warning',
            baseThemeKey: 'colorWarning',
            contrastThemeKey: 'colorWarningContrast',
        },
        {
            cssName: 'danger',
            baseThemeKey: 'colorError',
            contrastThemeKey: 'colorErrorContrast',
        },
    ]

    constructor(
        @Inject(DOCUMENT)
        private readonly document: Document,
        private readonly authService: AuthService,
        private readonly storage: Storage,
    ) {
    }

    public async initialize(): Promise<void> {
        this.highContrastEnabledSubject.next(
            await this.getStoredHighContrastSetting(),
        )

        this.highContrastEnabledSubject.pipe(
            switchMap((highContrastEnabled) => {
                return highContrastEnabled
                    ? of(this.highContrastTheme)
                    : this.authService.user$.pipe(
                        map(path<ClientThemeFragment | Nil>(['client', 'themes', 'app'])),
                        distinctUntilChangedEquals(),
                        map((fragment) => fragment && this.normalizeClientTheme(fragment)),
                    )
            }),
        ).subscribe((theme) => {
            if (isNil(theme)) this.clearTheme()
            else this.applyTheme(theme)
        })
    }

    protected normalizeClientTheme(clientTheme: ClientThemeFragment): Theme {
        return clientTheme // For now, this has exactly the same shape
    }

    protected applyTheme(theme: Theme): void {
        for (const { cssName, baseThemeKey, contrastThemeKey } of this.colorMaps) {
            const baseColor = colord(theme[baseThemeKey])
            const contrastColor = colord(theme[contrastThemeKey])

            const shadeColor = baseColor.darken(0.1)
            const tintColor = baseColor.lighten(0.1)

            this.setProperty(`--ion-color-${cssName}`, baseColor.toHex())
            this.setProperty(`--ion-color-${cssName}-rgb`, this.createRGBListString(baseColor))
            this.setProperty(`--ion-color-${cssName}-contrast`, contrastColor.toHex())
            this.setProperty(`--ion-color-${cssName}-contrast-rgb`, this.createRGBListString(contrastColor))
            this.setProperty(`--ion-color-${cssName}-shade`, shadeColor.toHex())
            this.setProperty(`--ion-color-${cssName}-shade-rgb`, this.createRGBListString(shadeColor))
            this.setProperty(`--ion-color-${cssName}-tint`, tintColor.toHex())
            this.setProperty(`--ion-color-${cssName}-tint-rgb`, this.createRGBListString(tintColor))
        }

        this.contextualColorsSubject.next(
            this.createContextualColorsMap(theme),
        )
    }

    protected clearTheme(): void {
        for (const { cssName } of this.colorMaps) {
            this.setProperty(`--ion-color-${cssName}`, null)
            this.setProperty(`--ion-color-${cssName}-rgb`, null)
            this.setProperty(`--ion-color-${cssName}-contrast`, null)
            this.setProperty(`--ion-color-${cssName}-contrast-rgb`, null)
            this.setProperty(`--ion-color-${cssName}-shade`, null)
            this.setProperty(`--ion-color-${cssName}-shade-rgb`, null)
            this.setProperty(`--ion-color-${cssName}-tint`, null)
            this.setProperty(`--ion-color-${cssName}-tint-rgb`, null)
        }

        this.contextualColorsSubject.next(
            {},
        )
    }

    protected setProperty(key: string, value: string | null): void {
        this.document.documentElement.style.setProperty(key, value)
    }

    protected createRGBListString(color: Colord): string {
        const { r, g, b } = color.toRgb()
        return `${r}, ${g}, ${b}`
    }

    protected createContextualColorsMap(theme: Theme): ContextualColorsMap {
        const contextualColorsMap: ContextualColorsMap = {}

        for (const { name, value } of theme.contextualColors) {
            contextualColorsMap[name] = value
        }

        return contextualColorsMap
    }

    // ------------------------------------------------------------------------------
    //      High-contrast theme setting
    // ------------------------------------------------------------------------------

    public async setHighContrastSetting(enabled: boolean): Promise<void> {
        await this.storage.set('settings:high-contrast-theme', enabled)
        this.highContrastEnabledSubject.next(enabled)
    }

    private async getStoredHighContrastSetting(): Promise<boolean> {
        const highContrastThemeEnabled = await this.storage.get('settings:high-contrast-theme')
        return isBoolean(highContrastThemeEnabled) ? highContrastThemeEnabled : false
    }
}
