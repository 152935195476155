import { Injector } from '@angular/core'
import { Router } from '@angular/router'
import { ApolloLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { FetchResult } from '@apollo/client/link/core'
import { onError } from '@apollo/client/link/error'
import { Observable as ZenObservable } from '@apollo/client/utilities'
import { RefreshTokenError } from '@app/errors/auth/RefreshTokenError'
import { AuthStateStore } from '@app/services/auth-storage/auth-storage.types'
import { AuthService } from '@app/services/auth/auth.service'
import { SafeGuardPath, SafeGuardsService } from '@app/services/safe-guards/safe-guards.service'
import Bugsnag from '@bugsnag/browser'
import { NavController } from '@ionic/angular'
import { none, pathEq, tap, toPairs } from 'ramda'
import { createUploadLink } from 'apollo-upload-client'
import { CountryService } from '@app/services/country/country.service'
import { apiGraphQlUrl } from '@lib/env/env.lib'

/**
 * Creates an {@link ApolloLink} that handles network errors by redirecting to the safe guards check.
 */
export function createNetworkErrorLink(safeGuardsService: SafeGuardsService): ApolloLink {
    return onError(({ networkError }) => {
        if (networkError) {
            safeGuardsService.redirectToGuard(SafeGuardPath.NoNetworkConnection)
        }
    })
}

/**
 * Creates an {@link ApolloLink} that handles authentication errors by redirecting to the login page.
 */
export function createApiAuthErrorLink(injector: Injector): ApolloLink {
    let tokenRefreshFailureHandler: Promise<void> | undefined

    const isAuthenticationError = pathEq(['extensions', 'category'], 'authentication')

    const failTokenRefresh = () => tokenRefreshFailureHandler ??= injector.get(AuthService)
        .flushAuthData()
        .then(() => injector.get(NavController).navigateRoot(['/auth/login'], {
            queryParams: { intendedUrl: injector.get(Router).url },
        }))
        .then(() => tokenRefreshFailureHandler = undefined)

    return onError(({ networkError, graphQLErrors, operation, forward }): ZenObservable<FetchResult> | void => {
        Bugsnag.leaveBreadcrumb('Received GraphQL Error response', { graphQLErrors, networkError })

        if (none(isAuthenticationError, graphQLErrors ?? [])) {
            return undefined
        }

        if (operation.operationName === 'login' || operation.operationName === 'localLogin') {
            return undefined
        }

        if (operation.operationName === 'refreshToken') {
            failTokenRefresh()
            return undefined
        }

        return new ZenObservable((observer) => {
            injector.get(AuthService)
                .refreshAccessToken()
                .then(() => forward(operation).subscribe({
                    next: (value) => observer.next(value),
                    error: (error) => observer.error(error),
                    complete: () => observer.complete(),
                }))
                .catch((error: RefreshTokenError) => {
                    Bugsnag.notify(error)
                    failTokenRefresh().then(() => observer.error(error))
                })
        })
    })
}

/**
 * Creates an Authentication {@link ApolloLink link} that adds the `Bearer` token sourced from given
 * {@link AuthStateStore auth-state store} as headers to all GraphQL requests.
 */
export function createApiAuthLink(authStateStore: AuthStateStore): ApolloLink {
    return setContext(() => {
        const currentTokens = authStateStore.getAuthState()
        return currentTokens ? { headers: { authorization: `Bearer ${currentTokens.accessToken}` } } : {}
    })
}

/**
 * Creates an Apollo link that performs logging of request IO to the bug tracker,
 * and registers debugging URL search-params to the operation contexts for
 * injection into the request URLs.
 */
export function createApiLogLink(): ApolloLink {
    let nextRequestId = 1
    return new ApolloLink((operation, forward) => {
        const requestId = nextRequestId++
        const { operationName, variables } = operation

        Bugsnag.leaveBreadcrumb(`Fetch #${requestId} | Init`, { requestId, operationName, variables })

        operation.setContext({
            searchParams: {
                requestId,
                operationName,
            },
        })

        return forward(operation).map(
            tap(({ errors, data, extensions }) => {
                Bugsnag.leaveBreadcrumb(`Fetch #${requestId} | Result`, { errors, data, extensions })
            }),
        )
    })
}

/**
 * Creates the terminating Apollo HTTP link for the given environment.
 */
export function createApiHttpLink(countryService: CountryService): ApolloLink {
    return createUploadLink({
        uri: (operation) => {
            const urlObject = new URL(
                apiGraphQlUrl(countryService.resolveSelectedCountryCode()),
            )

            for (const [key, value] of toPairs(operation.getContext().searchParams)) {
                urlObject.searchParams.append(key, value)
            }

            return urlObject.toString()
        },
    })
}
