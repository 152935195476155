import { errorMessage } from '@lib/common.lib'

export class APIVersionCheckError extends Error {

    /**
     * This method can be used to instantiate an API-version check error from another thrown error.
     */
    public static lift(this: unknown, error: unknown): APIVersionCheckError {
        return new APIVersionCheckError(errorMessage(error))
    }

    public override get name(): string {
        return 'APIVersionCheckError'
    }
}
