<ion-content>
    <div class="ion-padding">
        <div class="header">
            <ion-text color="primary">
                <h1 class="spacious-header">{{ headerTranslationKey | translate }}</h1>
            </ion-text>

            <app-underline-button
                *ngIf="withCountrySelectionLink"
                routerLink="/select-country"
                [text]="(countryService.countryName$ | async) ?? ''"
            />
        </div>

        <img class="image" [src]="imageUrl" alt=""/>

        <div class="links-bar" *ngIf="withSegmentButtons">
            <ion-button
                color="tertiary"
                [fill]="activeSegmentButton === 'login' ? 'solid' : 'outline'"
                [routerLink]="activeSegmentButton === 'login' ? '' : '/auth/login'"
            >
                {{ 'auth.login' | translate }}
            </ion-button>

            <ion-button
                color="tertiary"
                [fill]="registerLink.isActive ? 'solid' : 'outline'"
                routerLink="/auth/register"
                routerLinkActive
                #registerLink="routerLinkActive"
            >
                {{ 'auth.create-account' | translate }}
            </ion-button>
        </div>

        <ng-content></ng-content>
    </div>
</ion-content>
