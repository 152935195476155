import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from '@app/app.module'
import { environment } from '@env/environment'
import packageJson from '../package.json'
import Bugsnag from '@bugsnag/js'
import { Env } from '@env/environment.types'
import { register as registerSwiperComponents } from 'swiper/element/bundle'

registerSwiperComponents()

console.log(`Join Program app running on version ${packageJson.version}`)

Bugsnag.start({
    apiKey: '18da4fa993ec9f7a324c51c55c9c7768',
    releaseStage: environment.name,
    appVersion: packageJson.version,
    redactedKeys: [
        'password',
        'password_confirmation',
        'access_token',
        'refresh_token',
        'code',
    ],
    enabledReleaseStages: [
        Env.Name.Test,
        Env.Name.Acceptance,
        Env.Name.Production,
    ],
})

if (environment.productionMode) {
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((error) => {
        Bugsnag.notify(error)
        console.error(error)
    })
