import { NavigationEnd, Router } from '@angular/router'
import { filter, map, Observable } from 'rxjs'
import { startWith } from 'rxjs/operators'

export function watchUrls(router: Router): Observable<string> {
    return router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event) => event.url),
        startWith(router.url),
    )
}

export function matchUrls(router: Router, regex: RegExp): Observable<boolean> {
    return watchUrls(router).pipe(
        map((url) => regex.test(url)),
    )
}
