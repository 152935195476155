import { AbstractDeepLink, DeeplinkHandlerArgs } from '@app/services/deeplinks/links/AbstractDeepLink'

export class PkceCallbackDeeplink extends AbstractDeepLink {
    public readonly pathPattern: string = '/auth/pkce-callback'
    public readonly alias: string = 'pkceCallback'

    public async handler({ $event, router }: DeeplinkHandlerArgs): Promise<void> {
        const { queryString = '' } = $event.$link ?? {}
        await router.navigateByUrl(`/auth/pkce-callback?${queryString}`)
    }
}
