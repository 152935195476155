import { OrderStrategyEnum, RestaurantFragment, RestaurantOrderStrategyFragment } from '@app-graphql/api-schema'
import { isNil, propEq } from 'ramda'

export function resolveOrderStrategyValue(restaurant: RestaurantFragment, clientID: string): OrderStrategyEnum {
    return resolveOrderStrategyObject(restaurant, clientID).value
}

export function resolveOrderStrategyNote(restaurant: RestaurantFragment, clientID: string): string | null {
    return resolveOrderStrategyObject(restaurant, clientID).note ?? null
}

function resolveOrderStrategyObject(restaurant: RestaurantFragment, clientID: string): RestaurantOrderStrategyFragment {
    const object = restaurant.orderStrategies.find(propEq('clientID', clientID))

    if (isNil(object)) {
        throw new Error(`Failed to resolve OrderStrategy value for Restaurant ${restaurant.id}`)
    }

    return object
}
