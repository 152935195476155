/* eslint-disable max-len */
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators'
import { equals, identity } from 'ramda'
import { connectable, MonoTypeOperatorFunction, Observable, OperatorFunction, ReplaySubject } from 'rxjs'

/**
 * Returns the {@link distinctUntilChanged} operator applied to Ramda's {@link equals} function for
 * comparison of consecutive values. Optionally takes a selector function to compare a subset of the
 * stream values.
 */
export function distinctUntilChangedEquals<T>(selector: (x: T) => any = identity): MonoTypeOperatorFunction<T> {
    return distinctUntilChanged((x, y) => {
        const xx = selector(x)
        const yy = selector(y)
        return xx === yy || equals(xx, yy)
    })
}

export function shareReplayOne<T>(refCount: boolean = true): MonoTypeOperatorFunction<T> {
    return shareReplay({ bufferSize: 1, refCount })
}

// ------------------------------------------------------------------------------
//      Map operators over arrays
// ------------------------------------------------------------------------------

/**
 * Returns an {@link map RxJS map operator} applied to an array-iterating function
 * that returns `true` if **any** of the array's elements satisfy the given predicate function.
 */
export function mapAny<T>(predicate: (x: T, i: number, arr: readonly T[]) => boolean): OperatorFunction<readonly T[], boolean> {
    return map((xs) => {
        for (let i = 0; i < xs.length; i++) {
            if (predicate(xs[i], i, xs)) {
                return true
            }
        }

        return false
    })
}

/**
 * Returns an {@link map RxJS map operator} applied to an array-iterating function
 * that returns `true` if **all** of the array's elements satisfy the given predicate function.
 */
export function mapAll<T>(predicate: (x: T, i: number, arr: readonly T[]) => boolean): OperatorFunction<readonly T[], boolean> {
    return map((xs) => {
        for (let i = 0; i < xs.length; i++) {
            if (! predicate(xs[i], i, xs)) {
                return false
            }
        }

        return true
    })
}

/**
 * Returns an {@link map RxJS map operator} applied to an array-mapping function
 * that returns the mapped array for the given mapper function.
 */
export function mapArrayMap<T, U>(mapper: (x: T, i: number, arr: readonly T[]) => U): OperatorFunction<readonly T[], U[]> {
    return map((xs) => xs.map(mapper))
}

/**
 * Returns an {@link map RxJS map operator} applied to an array-reducing function
 * that returns the reduced value for the given reducer function and seed.
 */
export function mapReduce<T, U>(reducer: (acc: U, x: T, i: number, arr: readonly T[]) => U, seed: U): OperatorFunction<readonly T[], U> {
    return map((xs) => xs.reduce(reducer, seed))
}

/**
 * Takes a quantifier function from `T` to a number and returns an {@link map RxJS map operator}
 * that takes an observable of `T[]`'s and returns an observable of the sum of the applications of
 * `quantifier` to all contained `T`'s. (A specialization of {@link mapReduce})
 */
export function mapSum<T>(quantifier: (x: T) => number): OperatorFunction<readonly T[], number> {
    return mapReduce((sum, x) => sum + quantifier(x), 0)
}

export function mapSumBy<K extends string>(key: K): OperatorFunction<{ [key in K]: number }[], number> {
    return mapSum((obj) => obj[key])
}

/**
 * Returns an {@link map RxJS map operator} applied to
 * an array-filtering function
 * that returns the mapped array for the given filterer function.
 */
export function mapFilter<T>(filterer: (x: T, i: number, arr: readonly T[]) => boolean): OperatorFunction<readonly T[], T[]> {
    return map((xs) => xs.filter(filterer))
}

/**
 * Returns an {@link Observable RxJS Observable}
 * that is connected to the given observable, that means it not needs to be subscribed to return the last value that has been emitted.
 */
export function connectObservable<T>(obs: Observable<T>): Observable<T> {
    const connectableObs = connectable(
        obs,
        {
            connector: () => new ReplaySubject<T>(1),
            resetOnDisconnect: false,
        },
    )
    connectableObs.connect()
    return connectableObs
}
