import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { GraphQLModule } from '@app/modules/graphql/graphql.module'
import { CartService } from '@app/services/cart/cart.service'
import { UserService } from '@app/services/user/user.service'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx'
import { Camera } from '@awesome-cordova-plugins/camera/ngx'
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx'
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx'
import { Market } from '@awesome-cordova-plugins/market/ngx'
import { Network } from '@awesome-cordova-plugins/network/ngx'
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx'
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { TranslateModule } from '@ngx-translate/core'
import { BootService } from '@app/services/boot/boot.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx'
import { WrappersDomainModule } from '@app/domains/wrappers/wrappers-domain.module'
import { ionicModuleConfig } from '@app/config/ionic-module.config'
import { translateModuleConfig } from '@app/config/translate-module.config'
import { ionicStorageModuleConfig } from '@app/config/ionic-storage-module.config'
import { PkceDomainModule } from '@app/domains/pkce/pkce-domain.module'
import { UiDomainModule } from '@app/domains/ui/ui-domain.module'

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        IonicStorageModule.forRoot(ionicStorageModuleConfig),
        IonicModule.forRoot(ionicModuleConfig),
        AppRoutingModule,
        TranslateModule.forRoot(translateModuleConfig),
        GraphQLModule,
        HttpClientModule,
        BrowserAnimationsModule,
        WrappersDomainModule,
        PkceDomainModule,
        UiDomainModule,
    ],
    providers: [
        Camera,
        WebView,
        Deeplinks,
        StatusBar,
        Keyboard,
        SplashScreen,
        InAppBrowser,
        BarcodeScanner,
        InAppBrowser,
        CartService,
        UserService,
        Network,
        AppVersion,
        Market,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        {
            // https://github.com/bugsnag/bugsnag-js/issues/2057 Cannot use esbuild yet...
            provide: ErrorHandler,
            useFactory: () => new BugsnagErrorHandler(),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (bootService: BootService) => () => bootService.bootApplication(),
            deps: [BootService],
            multi: true,
        },
    ],
})
export class AppModule {
}
