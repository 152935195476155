import { Nil } from '@app/types/common.types'
import { isNil } from 'ramda'

export function isString(x: unknown): x is string {
    return typeof x === 'string'
}

export function isNumber(x: unknown): x is number {
    return typeof x === 'number'
}

export function isBoolean(x: unknown): x is boolean {
    return typeof x === 'boolean'
}

export function isNonEmpty<T>(xs: readonly T[]): xs is readonly [T, ...T[]] {
    return Array.isArray(xs) && xs.length > 0
}

export function expectNonNil<T>(x: T | Nil, message?: string): T | never {
    if (isNil(x)) {
        throw new Error(message ?? 'Encountered NIL value where NON-NIL is expected.')
    }

    return x
}
