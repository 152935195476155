import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { OrderStatusComponent } from './order-status/order-status.component'

@NgModule({
    declarations: [
        OrderStatusComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
    ],
    exports: [
        OrderStatusComponent,
    ],
})
export class OrderComponentModule {
}
