import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Platform } from '@ionic/angular'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'

@Component({
    selector: 'app-user-consent-checkbox',
    templateUrl: './user-consent-checkbox.component.html',
    styleUrls: ['./user-consent-checkbox.component.scss'],
})
export class UserConsentCheckboxComponent {

    @Input({ required: true })
    public control: FormControl<boolean>

    constructor(
        private readonly platform: Platform,
        private readonly inAppBrowser: InAppBrowser,
    ) {
    }

    public openTermsOfUse(): void {
        this.openLink('https://joinprogram.com/terms-of-use/')
    }

    public openPrivacyPolicy(): void {
        this.openLink('https://joinprogram.com/privacy-policy/')
    }

    private openLink(url: string): void {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            window.open(url, '_blank')
        } else {
            this.inAppBrowser.create(url, '_system')
        }
    }
}
