export enum CapacityLevel {
    UNLIMITED = 'unlimited',
    PLENTY = 'plenty',
    LITTLE = 'little',
    EXHAUSTED = 'exhausted',
}

export interface Capacity {
    readonly menuEntryOccurrenceID: string

    /** Equals `Infinity` for unlimited capacity */
    readonly amountTotal: number

    /** Equals `Infinity` for unlimited capacity */
    readonly amountAvailable: number
    readonly isConstrained: boolean
    readonly capacityLevel: CapacityLevel
}
