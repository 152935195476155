<app-auth-template [withCountrySelectionLink]="false">
    <ion-select
        class="custom"
        fill="outline"
        [formControl]="control"
        [label]="'country-select-page.select-label' | translate"
        labelPlacement="stacked"
    >
        <ion-select-option *ngFor="let country of countries" [value]="country.value">
            {{ country.name }}
        </ion-select-option>
    </ion-select>

    <ion-button
        [disabled]="! control.value || processing"
        (click)="setSelectedCountry()"
        expand="block"
    >
        <span [hidden]="processing">{{ 'country-select-page.submit-button' | translate }}</span>
        <ion-spinner [hidden]="! processing"/>
    </ion-button>
</app-auth-template>
