import { GraphQLError } from 'graphql'

export class LoginError extends Error {
    constructor(public readonly previous: GraphQLError) {
        super(previous.message)
    }

    public get isAuthenticationError(): boolean {
        return this.previous.extensions?.category === 'authentication'
    }

    public get isCredentialsError(): boolean {
        return this.isAuthenticationError
            && this.previous.extensions?.reason === 'Incorrect username or password'
    }
}
