import { head } from 'ramda'
import { isNumber } from '@lib/assertions/assertions.lib'

// ------------------------------------------------------------------------------
//      Options type
// ------------------------------------------------------------------------------

export type CachedDecoratorOptions<TArgs extends any[]> = {
    /**
     * Specify, in milliseconds, how long cached return values may be preserved. If `null`
     * the cache is preserved indefinitely.
     */
    TTL: number | null
} & (TArgs extends [string] ? {
    /**
     * Optionally provide a function that transforms the method's arguments into a unique cache key
     * for those arguments. By default, the first argument will be used as cache key, which is sufficient
     * for unary methods that take string values as their first (and only) argument.
     */
    KEY?: (args: TArgs) => string
} : {
    /**
     * Provide a function that transforms the method's arguments into a unique cache key
     * for those arguments.
     */
    KEY: (args: TArgs) => string
})

// ------------------------------------------------------------------------------
//      Internal types
// ------------------------------------------------------------------------------

type Method<TArgs extends any[]> = (this: any, ...args: TArgs) => any
type Descriptor<TArgs extends any[]> = TypedPropertyDescriptor<Method<TArgs>>

type CachedMethodDecorator<TArgs extends any[]> =
    (target: object, key: string, descriptor: Descriptor<TArgs>) => Descriptor<TArgs>

// ------------------------------------------------------------------------------
//      Decorator factory
// ------------------------------------------------------------------------------

/**
 * A method decorator factory that proxies the I/O of the decorated method with a simple cache layer.
 * Takes an {@link CachedDecoratorOptions options object} and returns the decorator function
 * that applies the caching layer.
 *
 * __
 *
 * **Do call this factory with an explicit type argument**. This will make sure that all the necessary type checking
 * is performed. The `TArgs` argument must be a tuple of the argument types of the decorated method:
 *
 * ```typescript
 * class Foo {
 *     @cached<[number, string[]]>({
 *         TTL: 10000,
 *         getKey: (n, strings) => n.toString(),
 *     })
 *     public bar(n: number, strings: string[]): any {
 *         // ...
*      }
 * }
 *```
 */
export function cached<TArgs extends any[]>(options: CachedDecoratorOptions<TArgs>): CachedMethodDecorator<TArgs> {

    const { TTL, KEY = head } = options

    const cache: Record<string, any> = {}

    return function (target, key, descriptor) {
        const originalFn = descriptor.value!
        descriptor.value = function (...args) {

            const cacheKey = KEY(args)

            if (cache[cacheKey]) {
                return cache[cacheKey]
            }

            cache[cacheKey] = originalFn.apply(this, args)

            if (isNumber(TTL)) {
                window.setTimeout(() => { delete cache[cacheKey] }, TTL)
            }

            return cache[cacheKey]
        }

        return descriptor
    }
}

// ------------------------------------------------------------------------------
//      TTL shortcuts
// ------------------------------------------------------------------------------

export const seconds = (n: number): number => n * 1000
export const minutes = (n: number): number => seconds(n * 60)
