export interface ScalarPKCEPair {
    verifier: string
    challenge: string
}

export class PkcePair {

    public static async new(): Promise<PkcePair> {
        const verifier = generateCodeVerifier()
        const challenge = await generateCodeChallenge(verifier)
        return new PkcePair(verifier, challenge)
    }

    public static async fromScalar(scalar: ScalarPKCEPair): Promise<PkcePair> {
        if (scalar.challenge !== await generateCodeChallenge(scalar.verifier)) {
            throw new Error('Invalid scalar PKCE pair.')
        }

        return new PkcePair(scalar.verifier, scalar.challenge)
    }

    readonly #challenge: string
    readonly #verifier: string

    private constructor(verifier: string, challenge: string) {
        this.#verifier = verifier
        this.#challenge = challenge
    }

    public getVerifier(): string {
        return this.#verifier
    }

    public getChallenge(): string {
        return this.#challenge
    }

    public toScalar(): ScalarPKCEPair {
        return {
            verifier: this.#verifier,
            challenge: this.#challenge,
        }
    }
}

/**
 * Generates a random code verifier.
 */
function generateCodeVerifier(): string {
    const array = new Uint32Array(56 / 2)
    window.crypto.getRandomValues(array)
    return Array.from(array, dec2hex).join('')
}

/**
 * Generates a matching code challenge from a given code verifier.
 */
async function generateCodeChallenge(verifier: string): Promise<string> {
    return base64urlEncode(
        await sha256(verifier),
    )
}

// ------------------------------------------------------------------------------
//      Private library functions
// ------------------------------------------------------------------------------

function dec2hex(dec: number): string {
    return ('0' + dec.toString(16)).slice(-2)
}

function sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder()
    const data = encoder.encode(plain)
    return window.crypto.subtle.digest('SHA-256', data)
}

function base64urlEncode(arrayBuffer: ArrayBuffer): string {
    let str = ''
    const bytes = new Uint8Array(arrayBuffer)
    const len = bytes.byteLength

    for (let i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i])
    }

    return btoa(str)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '')
}
