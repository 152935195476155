import { Injectable, Injector } from '@angular/core'
import { DeeplinkMatch, Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx'
import Bugsnag from '@bugsnag/browser'
import { AbstractDeepLink } from '@app/services/deeplinks/links/AbstractDeepLink'
import { PasswordResetDeeplink } from '@app/services/deeplinks/links/PasswordResetDeeplink'
import { EmailVerifyDeeplink } from '@app/services/deeplinks/links/EmailVerifyDeeplink'
import { CheckoutKickbackDeeplink } from '@app/services/deeplinks/links/CheckoutKickbackDeeplink'
import { OrderPickupDeeplink } from '@app/services/deeplinks/links/OrderPickupDeeplink'
import { PkceCallbackDeeplink } from '@app/services/deeplinks/links/PkceCallbackDeeplink'
import { Router } from '@angular/router'
import { isNil } from 'ramda'
import { catchError } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Platform } from '@ionic/angular'
import type { RequiresInitialization } from '@app/types/framework.types'

@Injectable({
    providedIn: 'root',
})
export class DeeplinksService implements RequiresInitialization {

    private readonly links: AbstractDeepLink[] = [
        new PasswordResetDeeplink(),
        new EmailVerifyDeeplink(),
        new CheckoutKickbackDeeplink(),
        new OrderPickupDeeplink(),
        new PkceCallbackDeeplink(),
    ]

    constructor(
        private readonly router: Router,
        private readonly injector: Injector,
        private readonly deeplinks: Deeplinks,
        private readonly platform: Platform,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            return
        }

        this.watchDeeplinkMatches().subscribe(($event) => this.handleDeeplinkMatch($event))
    }

    private watchDeeplinkMatches(paths: Record<string, string> = this.getPaths()): Observable<DeeplinkMatch> {
        return this.deeplinks.route(paths).pipe(
            catchError((noMatch) => {
                this.handleDeeplinkNoMatch(noMatch)
                return this.watchDeeplinkMatches()
            }),
        )
    }

    private async handleDeeplinkMatch($event: DeeplinkMatch): Promise<void> {
        Bugsnag.leaveBreadcrumb('DeeplinkMatch', $event, 'navigation')

        const link = this.links.find((x) => x.matches($event))

        if (isNil(link)) {
            return Bugsnag.notify('Failed to match DeeplinkMatch with link instance')
        }

        try {
            const { router, injector } = this
            await link.handler({ $event, router, injector })
        } catch (error: any) {
            Bugsnag.leaveBreadcrumb('Error while running Deeplink handler', undefined, 'error')
            Bugsnag.notify(error)
        }
    }

    private handleDeeplinkNoMatch(noMatch: any): void {
        Bugsnag.leaveBreadcrumb('Unmatched Deeplink', noMatch, 'error')
        Bugsnag.notify('No match found for activated Deeplink')
    }

    private getPaths(): Record<string, string> {
        return this.links.reduce((acc, { pathPattern, alias }) => ({ ...acc, [pathPattern]: alias }), {})
    }
}
