import { environment } from '@env/environment'
import { Env } from '@env/environment.types'
import { isNil } from 'ramda'

export class EnvironmentError extends Error {
    public override get name(): string {
        return 'EnvironmentError'
    }
}

export function apiHostName(country: Env.CountryCode): string {
    const { hostname } = resolveApiDetails(country)
    return hostname
}

export function apiOrigin(country: Env.CountryCode): string {
    const { hostname, schema } = resolveApiDetails(country)
    return `${schema}://${hostname}`
}

export function apiGraphQlUrl(country: Env.CountryCode): string {
    const { hostname, schema, graphqlPath } = resolveApiDetails(country)
    return `${schema}://${hostname}/${graphqlPath}`
}

export function apiClientCredentials(country: Env.CountryCode): {
    clientId: string
    clientSecret: string
} {
    const { clientId, clientSecret } = resolveApiDetails(country)
    return { clientId, clientSecret }
}

export function pubsubGraphQlUrl(country: Env.CountryCode): string {
    const { hostname, schema, graphqlPath } = resolvePubsubDetails(country)
    return `${schema}://${hostname}/${graphqlPath}`
}

// ------------------------------------------------------------------------------
//      Private environment accessors
// ------------------------------------------------------------------------------

function resolveApiDetails(country: Env.CountryCode): Env.ApiDetails {
    const details = environment.api[country]

    if (isNil(details)) {
        throw new EnvironmentError(`Failed to get API details for country "${country}".`)
    }

    return details
}

function resolvePubsubDetails(country: Env.CountryCode): Env.PubsubDetails {
    const details = environment.pubsub[country]

    if (isNil(details)) {
        throw new EnvironmentError(`Failed to get Pubsub details for country "${country}".`)
    }

    return details
}
