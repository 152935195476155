import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CountryService } from '@app/services/country/country.service'

@Component({
    selector: 'app-auth-template',
    templateUrl: './auth-template.component.html',
    styleUrls: ['./auth-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthTemplateComponent {

    @Input()
    public headerTranslationKey: string = 'auth.header'

    @Input()
    public imageUrl: string = '/assets/images/jp-people.png'

    /**
     * Determines whether the "Login" and "Create account" segment buttons are shown
     */
    @Input()
    public withSegmentButtons: boolean = false

    /**
     * Determines which of the segment buttons should be considered active.
     *
     * Has no effect when {@link withSegmentButtons [withSegmentButtons]}="false".
     */
    @Input()
    public activeSegmentButton: 'login' | 'register' = 'login'

    @Input()
    public withCountrySelectionLink: boolean = true

    constructor(
        public readonly countryService: CountryService,
    ) {
    }
}
