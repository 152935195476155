import { Injectable } from '@angular/core'
import { ApiStockNumbersFragment, DailyMenuItemFragment } from '@app-graphql/api-schema'
import { Capacity, CapacityLevel } from '@app/services/capacity/capacity.types'
import { clamp } from 'ramda'
import { StockUpdateFragment } from '@app-graphql/pubsub-schema'

/**
 * Utility service that generates {@link Capacity capacity objects} from various sources.
 */
@Injectable({
    providedIn: 'root',
})
export class CapacityFactoryService {

    /**
     * Generates an {@link CapacityLevel.UNLIMITED unlimited capacity} object with the
     * {@link Capacity.amountTotal `total`} and {@link Capacity.amountAvailable `available`}
     * properties both set to `Infinity`.
     */
    public unlimited(menuEntryOccurrenceID: string): Capacity {
        return {
            menuEntryOccurrenceID,
            amountTotal: Infinity,
            amountAvailable: Infinity,
            isConstrained: false,
            capacityLevel: CapacityLevel.UNLIMITED,
        }
    }

    /**
     * Generates an {@link CapacityLevel.EXHAUSTED exhausted capacity} object with the
     * {@link Capacity.amountTotal `total`} and {@link Capacity.amountAvailable `available`}
     * properties both set to `0`.
     */
    public zero(menuEntryOccurrenceID: string): Capacity {
        return {
            menuEntryOccurrenceID,
            amountTotal: 0,
            amountAvailable: 0,
            isConstrained: true,
            capacityLevel: CapacityLevel.EXHAUSTED,
        }
    }

    /**
     * Creates a {@link Capacity capacity object} for the given daily menu-item fragment.
     */
    public fromDailyMenuItem(item: DailyMenuItemFragment): Capacity {
        if (this.isConstrainedStock(item.stock)) {
            return this.createCapacity(
                item.sources.menuEntryOccurrence,
                item.stock.amountTotal,
                item.stock.amountAvailable,
            )
        }

        return this.unlimited(item.sources.menuEntryOccurrence)
    }

    /**
     * Creates a {@link Capacity capacity object} for the given stock update fragment.
     */
    public fromStockUpdate(update: StockUpdateFragment): Capacity {
        if (this.isConstrainedStock(update)) {
            return this.createCapacity(
                update.menuEntryOccurrenceID,
                update.amountTotal,
                update.amountAvailable,
            )
        }

        return this.unlimited(update.menuEntryOccurrenceID)
    }

    // ------------------------------------------------------------------------------
    //      Private implementation
    // ------------------------------------------------------------------------------

    /**
     * Utility to narrow the property types on constrained stock fragments.
     */
    private isConstrainedStock(stock: ApiStockNumbersFragment | StockUpdateFragment): stock is {
        isConstrained: true
        amountTotal: number
        amountAvailable: number
    } {
        return stock.isConstrained
    }

    private createCapacity(menuEntryOccurrenceID: string, amountTotal: number, amountAvailable: number): Capacity {
        return {
            menuEntryOccurrenceID,
            amountTotal,
            amountAvailable,
            isConstrained: true,
            capacityLevel: this.getCapacityLevel(amountTotal, amountAvailable),
        }
    }

    /**
     * Determines the appropriate capacity level for the given stock numbers.
     */
    private getCapacityLevel(amountTotal: number, amountAvailable: number): CapacityLevel {
        if (amountAvailable === 0) {
            return CapacityLevel.EXHAUSTED
        }

        // The threshold number of remaining stock for {@link CapacityLevel.LITTLE low capacity} warnings.
        // This number is constrained to an absolute minimum and maximum, but may within these bounds vary
        // based on a fraction 0.2 of the total capacity.
        const lowStockThreshold = clamp(
            5,
            20,
            Math.floor(0.2 * amountTotal),
        )

        return amountAvailable <= lowStockThreshold
            ? CapacityLevel.LITTLE
            : CapacityLevel.PLENTY
    }
}
