import { TranslateCompiler, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

export const translateModuleConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
    },
    compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
    },
}
