import { Injectable } from '@angular/core'
import {
    DailyMenuFilters,
    DailyMenuFragment,
    DailyMenuItemFragment,
    DailyMenuItemQueryService,
    DailyMenuQueryService,
} from '@app-graphql/api-schema'
import { firstValueFrom } from 'rxjs'
import { forceFreshFetch } from '@lib/apollo/apollo.lib'
import { AuthService } from '@app/services/auth/auth.service'

@Injectable({
    providedIn: 'root',
})
export class DailyMenuService {

    constructor(
        private readonly authService: AuthService,
        private readonly dailyMenuQueryService: DailyMenuQueryService,
        private readonly dailyMenuItemQueryService: DailyMenuItemQueryService,
    ) {
    }

    public async getDailyMenu(
        date: string,
        filters: DailyMenuFilters,
        allowCache: boolean = true,
    ): Promise<DailyMenuFragment | null> {
        const clientID = this.authService.getClientId()!

        const result = await firstValueFrom(this.dailyMenuQueryService.fetch(
            { clientID, date, filters },
            { fetchPolicy: forceFreshFetch(! allowCache) },
        ))

        return result.data.dailyMenu ?? null
    }

    public async getDailyMenuItem(
        menuEntryOccurrenceID: string,
        forceNetwork: boolean = false,
    ): Promise<DailyMenuItemFragment> {
        const result = await firstValueFrom(
            this.dailyMenuItemQueryService.fetch(
                { menuEntryOccurrenceID },
                { fetchPolicy: forceFreshFetch(forceNetwork) },
            ),
        )

        return result.data.dailyMenuItem
    }
}
