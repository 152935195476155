import { AbstractDeepLink, DeeplinkHandlerArgs } from '@app/services/deeplinks/links/AbstractDeepLink'

export class PasswordResetDeeplink extends AbstractDeepLink {
    public readonly pathPattern: string = '/email/reset/:token'
    public readonly alias: string = 'passwordReset'

    public async handler({ $event, router }: DeeplinkHandlerArgs): Promise<void> {
        const { token } = $event.$args
        const email = decodeURIComponent($event.$args.email)

        await router.navigate(['/auth/update-forgotten-password'], {
            queryParams: {
                email,
                token,
            },
        })
    }
}
