import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { UpdateUserMutationService } from '@app-graphql/api-schema'
import { formatDateTime } from '@lib/date-time/date-time.lib'
import { Router } from '@angular/router'
import { lastValueFrom } from 'rxjs'
import { AuthService } from '@app/services/auth/auth.service'

@Component({
    selector: 'app-consent-policies',
    templateUrl: './consent-policies.component.html',
    styleUrls: ['./consent-policies.component.scss'],
})
export class ConsentPoliciesComponent {

    public form = this.fb.group({
        consent: this.fb.nonNullable.control<boolean>(false, [
            Validators.requiredTrue,
        ]),
    })

    public isLoading: boolean = false

    constructor(
        private readonly fb: FormBuilder,
        private readonly updateUserMutationService: UpdateUserMutationService,
        private readonly router: Router,
        private readonly authService: AuthService,
    ) {
    }

    public async finishConsent() {
        this.isLoading = true

        try {
            await lastValueFrom(this.updateUserMutationService.mutate({
                input: {
                    privacyPolicyAcceptedAt: formatDateTime(),
                    termsAcceptedAt: formatDateTime(),
                },
            }))

            await this.authService.refreshUser()

            await this.router.navigate(['/home'])
        } catch {
            //todo

        } finally {
            this.isLoading = false
        }
    }
}
