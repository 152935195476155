import { AbstractDeepLink, DeeplinkHandlerArgs } from '@app/services/deeplinks/links/AbstractDeepLink'

export class EmailVerifyDeeplink extends AbstractDeepLink {
    public readonly pathPattern: string = '/email/verify/:userId/:token'
    public readonly alias: string = 'emailVerify'

    public async handler({ $event, router }: DeeplinkHandlerArgs): Promise<void> {
        const urlBase64 = btoa($event.$link.url)

        await router.navigate(['/auth/email-verification'], {
            queryParams: { urlBase64, url: $event.$link.url },
        })
    }
}
