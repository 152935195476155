import type { Router } from '@angular/router'
import type { Injector } from '@angular/core'
import { DeeplinkMatch } from '@awesome-cordova-plugins/deeplinks/ngx'

export interface DeeplinkHandlerArgs {
    $event: DeeplinkMatch
    router: Router
    injector: Injector
}

export abstract class AbstractDeepLink {
    public abstract readonly pathPattern: string
    public abstract readonly alias: string
    public abstract handler(args: DeeplinkHandlerArgs): Promise<void>

    public matches($event: DeeplinkMatch): boolean {
        return $event.$route === this.alias
    }
}
