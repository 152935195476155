import { InjectionToken } from '@angular/core'
import { Platform } from '@ionic/angular'
import { CountryService } from '@app/services/country/country.service'
import { apiHostName } from '@lib/env/env.lib'

export const PKCE_CALLBACK_URI = new InjectionToken<string>('PKCE_CALLBACK_URI')

export function pkceCallbackUriFactory(platform: Platform, countryService: CountryService): string {
    if (platform.is('desktop') || platform.is('mobileweb')) {
        return `${window.location.origin}/auth/pkce-callback`
    }

    const apiHostname = apiHostName(countryService.resolveSelectedCountryCode())
    return `com.pxlwidgets.join.program://${apiHostname}/auth/pkce-callback`
}
