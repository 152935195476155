import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AllergenWarningComponent } from '@app/components/allergen-warning/allergen-warning.component'
import { CapacityComponent } from '@app/components/capacity/capacity.component'
import { OrderDeadlineNoteComponent } from '@app/components/feed/order-deadline-note/order-deadline-note.component'
import { OrderComponent } from '@app/components/order-history/order/order.component'
import { OrderComponentModule } from '@app/components/order/order.module'
import { UiDomainModule } from '@app/domains/ui/ui-domain.module'

import { EqualsPipe } from '@app/pipes/equals/equals.pipe'
import { IncludesPipe } from '@app/pipes/includes/includes.pipe'
import { IsEmptyPipe } from '@app/pipes/is-empty/is-empty.pipe'
import { IsFuturePipe } from '@app/pipes/is-future/is-future.pipe'
import { IsNonEmptyPipe } from '@app/pipes/is-nonempty/is-nonempty.pipe'
import { IsPastPipe } from '@app/pipes/is-past/is-past.pipe'
import { IsTodayPipe } from '@app/pipes/is-today/is-today.pipe'
import { IsTomorrowPipe } from '@app/pipes/is-tomorrow/is-tomorrow.pipe'
import { KeysPipe } from '@app/pipes/keys/keys.pipe'
import { NegatePipe } from '@app/pipes/negate/negate.pipe'
import { PaymentMethodPipe } from '@app/pipes/payment-method/payment-method.pipe'
import { PaymentStatusPipe } from '@app/pipes/payment-status/payment-status.pipe'
import { PluralTranslatePipe } from '@app/pipes/plural-translate/plural-translate.pipe'
import { RemainingCapacityPipe } from '@app/pipes/remaining-capacity/remaining-capacity.pipe'
import { SortDescPipe } from '@app/pipes/sort-desc/sort-desc.pipe'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import {
    CalculatePayedWithVouchersPipe,
} from '@app/pipes/calculate-payed-with-vouchers/calculate-payed-with-vouchers.pipe'
import { DishRatingComponent } from '@app/components/feed/dish-rating/dish-rating.component'
import {
    FeedOrderedDishRatingComponent,
} from '@app/domains/feed/components/feed-ordered-dish-rating/feed-ordered-dish-rating.component'

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        UiDomainModule,
        TranslateModule,
        OrderComponentModule,
    ],
    declarations: [
        CapacityComponent,
        AllergenWarningComponent,
        OrderDeadlineNoteComponent,
        OrderComponent,

        // Pipes
        RemainingCapacityPipe,
        PluralTranslatePipe,
        IsTodayPipe,
        IsTomorrowPipe,
        IsFuturePipe,
        IsPastPipe,
        IncludesPipe,
        IsEmptyPipe,
        IsNonEmptyPipe,
        EqualsPipe,
        NegatePipe,
        KeysPipe,
        SortDescPipe,
        PaymentStatusPipe,
        PaymentMethodPipe,
        CalculatePayedWithVouchersPipe,
        DishRatingComponent,
        FeedOrderedDishRatingComponent,
    ],
    exports: [
        // Modules
        CommonModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        UiDomainModule,

        // Pipes
        RemainingCapacityPipe,
        PluralTranslatePipe,
        IsTodayPipe,
        IsTomorrowPipe,
        IsFuturePipe,
        IsPastPipe,
        IncludesPipe,
        IsEmptyPipe,
        IsNonEmptyPipe,
        EqualsPipe,
        NegatePipe,
        KeysPipe,
        SortDescPipe,
        PaymentStatusPipe,
        PaymentMethodPipe,
        CalculatePayedWithVouchersPipe,

        // Components
        OrderDeadlineNoteComponent,
        CapacityComponent,
        AllergenWarningComponent,
        OrderComponent,
        DishRatingComponent,
        FeedOrderedDishRatingComponent,
    ],
})
export class SharedModule {
}
