import { NgModule } from '@angular/core'
import { UiDomainModule } from '@app/domains/ui/ui-domain.module'
import { SharedModule } from '@app/modules/shared/shared.module'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { AuthenticatedComponent } from './components/authenticated/authenticated.component'

@NgModule({
    imports: [
        IonicModule,
        SharedModule,
        UiDomainModule,
        TranslateModule,
    ],
    declarations: [
        AuthenticatedComponent,
    ],
    exports: [
        AuthenticatedComponent,
    ],
})
export class WrappersDomainModule {
}
