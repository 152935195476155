/**
 * Statuses of API compatibility.
 */
export enum ApiCompatibilityStatus {

    /**
     * Indicates that the tested API's version is within a given Semver range.
     */
    InRange = 'api-version-in-range',

    /**
     * Indicates that the tested API's version is greater than a given Semver range.
     */
    GtRange = 'api-version-gt-range',

    /**
     * Indicates that the tested API's version is less than a given Semver range.
     */
    LtRange = 'api-version-lt-range',

    /**
     * Indicates that the tested API's version is unknown, possible causes are
     * connection errors or invalid version strings returned by the API.
     */
    Unknown = 'api-version-unknown',
}
