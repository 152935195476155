import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { errorMessage } from '@lib/common.lib'
import { Platform } from '@ionic/angular'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT)
        private readonly document: Document,
        private readonly platform: Platform,
    ) {
    }

    public ngOnInit(): void {
        this.setDesktopStyles()
    }

    private setDesktopStyles(): void {
        try {
            if (this.platform.is('desktop')) {
                this.document.documentElement.classList.add('desktop')
            }
        } catch (error: unknown) {
            console.error(`Error at AppComponent::setDesktopStyles(): ${errorMessage(error)}`)
            // Do nothing otherwise...
        }
    }
}
