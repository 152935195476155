import { Injectable } from '@angular/core'
import { AuthStateStore, AuthStorageKey } from '@app/services/auth-storage/auth-storage.types'
import { Storage } from '@ionic/storage'
import { clone } from 'ramda'
import { AuthState, isValidAuthState } from '@lib/auth/auth.lib'

@Injectable({
    providedIn: 'root',
})
export class AuthStorageService implements AuthStateStore {
    private authState: AuthState | null = null

    constructor(
        private storage: Storage,
    ) { }

    public getAuthState(): AuthState | null {
        return this.authState
    }

    public hasAuthState(): boolean {
        return this.authState !== null
    }

    /**
     * Submits the given auth-state to local storage and then to the AuthState observable streams.
     */
    public async submitAuthState(state: AuthState): Promise<void> {
        if (! isValidAuthState(state)) {
            throw new Error('Invalid auth state.')
        }

        await Promise.all([
            this.storage.set(AuthStorageKey.ACCESS_TOKEN, state.accessToken),
            this.storage.set(AuthStorageKey.TOKEN_TYPE, state.tokenType),
            this.storage.set(AuthStorageKey.REFRESH_TOKEN, state.refreshToken),
            this.storage.set(AuthStorageKey.EXPIRES_AT, state.expiresAt),
        ])

        this.authState = clone(state)
    }

    /**
     * Removes all AuthState information from local storage and pushes `null` into the
     * AuthState observable streams.
     */
    public async clearAuthState(): Promise<void> {
        await Promise.all([
            this.storage.remove(AuthStorageKey.ACCESS_TOKEN),
            this.storage.remove(AuthStorageKey.TOKEN_TYPE),
            this.storage.remove(AuthStorageKey.REFRESH_TOKEN),
            this.storage.remove(AuthStorageKey.EXPIRES_AT),
        ])

        this.authState = null
    }

    /**
     * Initializes the AuthState for the application: Reads the currently stored state, and
     * if it is valid it will be pushed into the AuthState observable streams. Otherwise it
     * removes any partial data that is stored locally, and pushes `null` into the streams.
     */
    public async initialize(): Promise<AuthState | null> {
        const initialState = await this.readPersistedAuthState()

        if (initialState !== null) {
            await this.submitAuthState(initialState)
        } else {
            await this.clearAuthState()
        }

        return this.authState
    }

    /**
     * Reads and returns the locally stored AuthState data. Returns null instead if a
     * fully valid state object cannot be constructed from the stored data.
     */
    private async readPersistedAuthState(): Promise<AuthState | null> {
        const [accessToken, tokenType, refreshToken, expiresAt] = await Promise.all([
            this.storage.get(AuthStorageKey.ACCESS_TOKEN),
            this.storage.get(AuthStorageKey.TOKEN_TYPE),
            this.storage.get(AuthStorageKey.REFRESH_TOKEN),
            this.storage.get(AuthStorageKey.EXPIRES_AT),
        ])

        const state: Partial<AuthState> = { accessToken, tokenType, refreshToken, expiresAt }

        return isValidAuthState(state) ? state : null
    }
}
