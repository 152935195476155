import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { RestaurantSelectorComponent } from '@app/components/feed/restaurant-selector/restaurant-selector.component'
import { IonicModule } from '@ionic/angular'
import { TranslateModule } from '@ngx-translate/core'
import { AddressLinesComponent } from './components/address-lines/address-lines.component'
import { BackButtonComponent } from './components/back-button/back-button.component'
import { CardComponent } from './components/card/card.component'
import { ClientLogoComponent } from './components/client-logo/client-logo.component'
import { DividerComponent } from './components/divider/divider.component'
import { FilterButtonComponent } from './components/filter-button/filter-button.component'
import { FormControlErrorsComponent } from './components/form-control-errors/form-control-errors.component'
import { HeaderComponent } from './components/header/header.component'
import { IconComponent } from './components/icon/icon.component'
import { ImageWithCounterComponent } from './components/image-with-counter/image-with-counter.component'
import { LoadingPageComponent } from './components/loading-page/loading-page.component'
import { MenuComponent } from './components/menu/menu.component'
import { PrintTimeslotComponent } from './components/print-timeslot/print-timeslot.component'
import { StickySegmentHeadingComponent } from './components/sticky-segment-heading/sticky-segment-heading.component'
import { SvgToIconComponent } from './components/svg-to-icon/svg-to-icon.component'
import { TextBalloonComponent } from './components/text-balloon/text-balloon.component'
import { TotalPriceComponent } from './components/total-price/total-price.component'
import { BackgroundImageDirective } from './directives/background-image/background-image.directive'
import { DishBackgroundImagePipe } from './pipes/dish-background-image/dish-background-image.pipe'
import { FormatPricePipe } from './pipes/format-price/format-price.pipe'
import { FullAddressLinesPipe } from './pipes/full-address-lines/full-address-lines.pipe'
import { IsNotNilPipe } from './pipes/is-not-nil/is-not-nil.pipe'
import { JoinListPipe } from './pipes/join-list/join-list.pipe'
import { ParseDateTimePipe } from './pipes/parse-date-time/parse-date-time.pipe'
import { RelativeDayPipe } from './pipes/relative-day/relative-day.pipe'
import { UserNamePipe } from './pipes/user-name/user-name.pipe'
import { IsFinitePipe } from './pipes/is-finite/is-finite.pipe'
import { ContextualColorDirective } from './directives/contextual-color/contextual-color.directive'
import { CssUrlPipe } from '@app/pipes/css-url/css-url.pipe'
import { PluckPipe } from './pipes/pluck/pluck.pipe'
import { WarningBadgeComponent } from '@app/domains/ui/components/warning-badge/warning-badge.component'
import { UnderlineButtonComponent } from '@app/domains/ui/components/underline-button/underline-button.component'
import { DateHeadingComponent } from '@app/domains/ui/components/date-heading/date-heading.component'
import { ParseDatePipe } from '@app/domains/ui/pipes/parse-date/parse-date.pipe'
import { AnyMealHasAllergenWarningPipe } from './pipes/any-meal-has-allergen-warning/any-meal-has-allergen-warning.pipe'
import { AppNutrientsInfoComponent } from '@app/domains/ui/components/app-nutrients-info/app-nutrients-info.component'
import {
    NutrientInfoPopupComponent,
} from '@app/domains/ui/components/nutrient-info-popup/nutrient-info-popup.component'
import { NutrientTypeTranslationPipe } from './pipes/nutrient-type-translation/nutrient-type-translation.pipe'
import {
    ShowPriceBasedOnOrderStrategyPipe,
} from './pipes/showPriceBasedOnOrderStrategy/show-price-based-on-order-strategy.pipe'
import { UpgradeTypeTranslatePipe } from '@app/pipes/upgrade-type/upgrade-type-translate.pipe'
import { OrderIsCancelablePipe } from '@app/pipes/order-is-cancelable/order-is-cancelable.pipe'
import { ClosingTimePipe } from '@app/domains/ui/pipes/closing-time/closing-time'
import { AuthTemplateComponent } from '@app/domains/ui/components/auth-template/auth-template.component'
import {
    UserConsentCheckboxComponent,
} from '@app/domains/ui/components/user-consent-checkbox/user-consent-checkbox.component'
import { DietaryClassLabelPipe } from './pipes/dietary-class-label/dietary-class-label.pipe'
import { UpdateAvailableComponent } from '@app/components/update-available/update-available.component'
import { OrderComponentModule } from '@app/components/order/order.module'
import { OrderStrategyPipe } from '@app/domains/ui/pipes/order-strategy/order-strategy.pipe'
import { OrderStrategyNotePipe } from '@app/domains/ui/pipes/order-strategy-note/order-strategy-note.pipe'
import { VoucherComponent } from '@app/domains/ui/components/voucher/voucher.component'
import { ToTopButtonComponent } from '@app/domains/ui/components/to-top-button/to-top-button.component'
import { IsOpenNowPipe } from '@app/pipes/is-open-now/is-open-now'
import { IsOpenTodayPipe } from '@app/pipes/is-open-today/is-open-today.pipe'
import { PollComponent } from '@app/components/feed/poll/poll.component'
import { EmojiPollComponent } from '@app/components/feed/poll/emoji-poll/emoji-poll.component'
import {
    MultipleChoicePollComponent,
} from '@app/components/feed/poll/multiple-choice-poll/multiple-choice-poll.component'
import { OpenQuestionPollComponent } from '@app/components/feed/poll/open-question-poll/open-question-poll.component'
import { StarPollComponent } from '@app/components/feed/poll/star-poll/star-poll.component'
import {
    DishRatingOnDetailIsEnabledPipe,
} from './pipes/dish-rating-on-detail-is-enabled/dish-rating-on-detail-is-enabled.pipe'
import { IsOpenOnDayPipe } from '@app/pipes/is-open-on-day/is-open-on-day.pipe'
import { DefaultPipe } from './pipes/default/default.pipe'

/**
 * Declares and exports generic ionic-components, directives and pipes used across the entire application.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        OrderComponentModule,
    ],
    declarations: [
        // ------------------------------------------------------------------------------
        //      Components
        // ------------------------------------------------------------------------------
        AddressLinesComponent,
        BackButtonComponent,
        CardComponent,
        DividerComponent,
        FormControlErrorsComponent,
        IconComponent,
        ImageWithCounterComponent,
        LoadingPageComponent,
        PrintTimeslotComponent,
        RestaurantSelectorComponent,
        StickySegmentHeadingComponent,
        TextBalloonComponent,
        TotalPriceComponent,
        FilterButtonComponent,
        SvgToIconComponent,
        ClientLogoComponent,
        MenuComponent,
        HeaderComponent,
        WarningBadgeComponent,
        UnderlineButtonComponent,
        AppNutrientsInfoComponent,
        NutrientInfoPopupComponent,
        AuthTemplateComponent,
        UserConsentCheckboxComponent,
        UpdateAvailableComponent,
        VoucherComponent,
        PollComponent,
        EmojiPollComponent,
        MultipleChoicePollComponent,
        OpenQuestionPollComponent,
        StarPollComponent,

        // ------------------------------------------------------------------------------
        //      Pipes
        // ------------------------------------------------------------------------------
        DishBackgroundImagePipe,
        FormatPricePipe,
        FullAddressLinesPipe,
        JoinListPipe,
        IsFinitePipe,
        IsNotNilPipe,
        NutrientTypeTranslationPipe,
        ParseDatePipe,
        ParseDateTimePipe,
        RelativeDayPipe,
        UserNamePipe,
        CssUrlPipe,
        PluckPipe,
        UpgradeTypeTranslatePipe,
        OrderIsCancelablePipe,
        ShowPriceBasedOnOrderStrategyPipe,
        AnyMealHasAllergenWarningPipe,
        ClosingTimePipe,
        OrderStrategyPipe,
        OrderStrategyNotePipe,
        DietaryClassLabelPipe,
        IsOpenNowPipe,
        IsOpenTodayPipe,
        IsOpenOnDayPipe,
        DishRatingOnDetailIsEnabledPipe,

        // ------------------------------------------------------------------------------
        //      Directives
        // ------------------------------------------------------------------------------
        BackgroundImageDirective,
        ContextualColorDirective,
        DateHeadingComponent,
        ToTopButtonComponent,
        DefaultPipe,
    ],
    exports: [
        // ------------------------------------------------------------------------------
        //      Components
        // ------------------------------------------------------------------------------
        AddressLinesComponent,
        BackButtonComponent,
        CardComponent,
        DividerComponent,
        FormControlErrorsComponent,
        IconComponent,
        ImageWithCounterComponent,
        LoadingPageComponent,
        PrintTimeslotComponent,
        RestaurantSelectorComponent,
        StickySegmentHeadingComponent,
        TextBalloonComponent,
        TotalPriceComponent,
        FilterButtonComponent,
        SvgToIconComponent,
        ClientLogoComponent,
        MenuComponent,
        HeaderComponent,
        WarningBadgeComponent,
        UnderlineButtonComponent,
        NutrientInfoPopupComponent,
        AuthTemplateComponent,
        UserConsentCheckboxComponent,
        VoucherComponent,
        PollComponent,
        EmojiPollComponent,
        MultipleChoicePollComponent,
        OpenQuestionPollComponent,
        StarPollComponent,

        // ------------------------------------------------------------------------------
        //      Pipes
        // ------------------------------------------------------------------------------
        DishBackgroundImagePipe,
        FormatPricePipe,
        FullAddressLinesPipe,
        IsFinitePipe,
        IsNotNilPipe,
        JoinListPipe,
        NutrientTypeTranslationPipe,
        ParseDateTimePipe,
        ParseDatePipe,
        RelativeDayPipe,
        UserNamePipe,
        CssUrlPipe,
        PluckPipe,
        UpgradeTypeTranslatePipe,
        OrderIsCancelablePipe,
        ShowPriceBasedOnOrderStrategyPipe,
        AnyMealHasAllergenWarningPipe,
        ClosingTimePipe,
        OrderStrategyPipe,
        OrderStrategyNotePipe,
        DietaryClassLabelPipe,
        IsOpenNowPipe,
        IsOpenTodayPipe,
        IsOpenOnDayPipe,
        DishRatingOnDetailIsEnabledPipe,

        // ------------------------------------------------------------------------------
        //      Directives
        // ------------------------------------------------------------------------------
        BackgroundImageDirective,
        ContextualColorDirective,
        DateHeadingComponent,
        AppNutrientsInfoComponent,
        UpdateAvailableComponent,
        ToTopButtonComponent,
        DefaultPipe,
    ],
})
export class UiDomainModule {
}
