import { Inject, Injectable } from '@angular/core'
import { isNil } from 'ramda'
import { BehaviorSubject, EMPTY, Observable, startWith } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { Capacity } from '@app/services/capacity/capacity.types'
import { DailyMenuItemFragment } from '@app-graphql/api-schema'
import { StockUpdateFragment, StockUpdatesSubscriptionService } from '@app-graphql/pubsub-schema'
import { distinctUntilChangedEquals } from '@lib/rxjs/rxjs.lib'
import { AuthService } from '@app/services/auth/auth.service'
import { RequiresInitialization } from '@app/types/framework.types'
import { WEBSOCKET_REFRESHED } from '@app/modules/graphql/graphql.module'
import { CapacityFactoryService } from '@app/services/capacity/factory/capacity-factory.service'

type MenuEntryOccurrenceID = string & {}
type CapacityMap = Partial<Record<MenuEntryOccurrenceID, Capacity>>

@Injectable({
    providedIn: 'root',
})
export class CapacityService implements RequiresInitialization {

    private readonly capacityMap$$ = new BehaviorSubject<CapacityMap>({})

    constructor(
        @Inject(WEBSOCKET_REFRESHED)
        private readonly websocketRefreshed$: Observable<void>,
        private readonly authService: AuthService,
        private readonly factory: CapacityFactoryService,
        private readonly stockUpdatesSubscriptionService: StockUpdatesSubscriptionService,
    ) {
    }

    public async initialize(): Promise<void> {
        this.authService.clientShortcode$.pipe(
            switchMap((maybeShortCode) => isNil(maybeShortCode) ? EMPTY : this.websocketRefreshed$.pipe(
                map(() => maybeShortCode),
                startWith(maybeShortCode),
            )),
            switchMap((shortCode) => this.watchClientStockUpdates(shortCode)),
        ).subscribe((update) => this.registerStockUpdate(update))
    }

    /**
     * Returns an observable that emits the current capacity for the given daily menu item over time.
     */
    public watchCapacity(item: DailyMenuItemFragment): Observable<Capacity> {
        return this.capacityMap$$.pipe(
            map((capacityMap) => capacityMap[item.sources.menuEntryOccurrence] ?? this.registerInitialStock(item)),
            distinctUntilChangedEquals(),
        )
    }

    // ------------------------------------------------------------------------------
    //      Private helper functions
    // ------------------------------------------------------------------------------

    private watchClientStockUpdates(clientShortcode: string): Observable<StockUpdateFragment> {
        return this.stockUpdatesSubscriptionService.subscribe({ clientShortcode }).pipe(
            map((update) => update.data?.stockUpdates),
            filter((update): update is StockUpdateFragment => ! isNil(update)),
        )
    }

    private registerStockUpdate(update: StockUpdateFragment): Capacity {
        return this.registerCapacity(
            this.factory.fromStockUpdate(update),
        )
    }

    private registerInitialStock(item: DailyMenuItemFragment): Capacity {
        return this.registerCapacity(
            this.factory.fromDailyMenuItem(item),
        )
    }

    private registerCapacity<T extends Capacity>(capacity: T): T {
        this.capacityMap$$.next(
            { ...this.capacityMap$$.getValue(), [capacity.menuEntryOccurrenceID]: capacity },
        )

        return capacity
    }
}
