import { Injectable } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { Storage } from '@ionic/storage'
import { isString } from '@lib/assertions/assertions.lib'
import { isNil } from 'ramda'
import type { Nil } from '@app/types/common.types'
import { SafeGuardsStorageKey } from './safe-guards-storage.types'

export enum SafeGuardPath {
    NoNetworkConnection = 'no-network-connection',
    NoApiConnection = 'no-api-connection',
    AppUpdateAvailable = 'app-update-available',
    ServerNotUpToDate = 'server-not-up-to-date',
}

@Injectable({
    providedIn: 'root',
})
export class SafeGuardsService {
    constructor(
        private readonly router: Router,
        private readonly storage: Storage,
    ) {
    }

    public createGuardUrlTree(guard: SafeGuardPath): UrlTree {
        return this.router.createUrlTree(['safe-guards', guard])
    }

    public async redirectToGuard(guard: SafeGuardPath): Promise<void> {
        if (! this.router.url.includes('safe-guards')) {
            await this.setIntendedUrl(this.router.url)
        }

        await this.router.navigateByUrl(
            this.createGuardUrlTree(guard),
        )
    }

    public async attemptIntendedUrl(): Promise<boolean> {
        if (! this.router.url.includes('safe-guards')) {
            return true
        }

        return this.router.navigateByUrl(
            await this.getIntendedUrl(),
        )
    }

    public async getIntendedUrl(): Promise<UrlTree> {
        const savedUrl: string | Nil = await this.storage.get(SafeGuardsStorageKey.CALLBACK_URL)

        return isNil(savedUrl)
            ? this.router.createUrlTree(['home'])
            : this.router.parseUrl(savedUrl)
    }

    private async setIntendedUrl(url: UrlTree | string): Promise<void> {
        await this.storage.set(
            SafeGuardsStorageKey.CALLBACK_URL,
            isString(url) ? url : this.router.serializeUrl(url),
        )
    }
}
