import {
    DietaryClassFragment,
    MenuFeedFilterInput,
    ProductCategoryFragment,
    ProductTypeEnum,
    RestaurantFragment,
    RestaurantTypeEnum,
} from '@app-graphql/api-schema'
import { filterMap } from '@lib/array/array.lib'
import { Mutable } from '@app/types/common.types'
import { clone, pipe, prop } from 'ramda'
import Bugsnag from '@bugsnag/js'

export interface CategoryFilter {
    category: ProductCategoryFragment
    isSelected: boolean
}

export interface RestaurantFilter {
    restaurant: RestaurantFragment
    isSelected: boolean
}

export interface DietaryClassFilter {
    dietaryClass: DietaryClassFragment
    isSelected: boolean
}

export class FeedFilter {

    public static empty(): FeedFilter {
        return new FeedFilter('', true, [], [], true, [])
    }

    /**
     * It will be true if any category has {@link CategoryFilter.isSelected}: true
     */
    public get isCategoriesFiltered(): boolean {
        return this.categories.some(prop('isSelected'))
    }

    /**
     * It will be true if any restaurant has {@link RestaurantFilter.isSelected}: true
     */
    public get isRestaurantsFiltered(): boolean {
        return this.restaurants.some(prop('isSelected'))
    }

    public get isDietaryClassesFiltered(): boolean {
        return this.dietaryClasses.some(prop('isSelected'))
    }

    /**
     * It will be true if:
     * {@link isCategoriesFiltered}: true OR {@link isRestaurantsFiltered}: true
     */
    public get isFiltered(): boolean {
        return this.isCategoriesFiltered || this.isRestaurantsFiltered
    }

    /**
     * This list holds ALL available categories as fetched from the productCategories query
     */
    public readonly categories: CategoryFilter[] = []

    /**
     * This list holds ALL available restaurants as fetched from the restaurants query
     */
    public readonly restaurants: RestaurantFilter[] = []

    /**
     * This list holds ALL available dietaryClasses as fetched from the DietaryClass query
     */
    public readonly dietaryClasses: DietaryClassFilter[] = []

    public onlyShowOfficeDays: boolean

    /**
     * It returns the amount of restaurants that are selected
     */
    public get totalSelectedRestaurants(): number {
        return this.isRestaurantsFiltered ? this.restaurants.filter(prop('isSelected')).length : 0
    }

    /**
     * It returns the amount of categories that are selected
     */
    public get totalSelectedCategories(): number {
        return this.isCategoriesFiltered ? this.categories.filter(prop('isSelected')).length : 0
    }

    public get totalSelectedDietaryClasses(): number {
        return this.isDietaryClassesFiltered ? this.dietaryClasses.filter(prop('isSelected')).length : 0
    }

    public get totalSelectedFilters(): number {
        return this.totalSelectedRestaurants + this.totalSelectedCategories + this.totalSelectedDietaryClasses
    }

    /**
     * If it's false it means the user has not applied the filter yet
     */
    private isApplied: boolean

    /**
     * It will be used for the filter input
     */
    private locationId: string

    constructor(
        locationId: string,
        isApplied = false,
        restaurants: RestaurantFilter[] = [],
        categories: CategoryFilter[] = [],
        onlyShowOfficeDays = true,
        dietaryClasses: DietaryClassFilter[] = [],
    ) {
        this.locationId = locationId
        this.isApplied = isApplied
        this.categories = categories
        this.restaurants = restaurants
        this.onlyShowOfficeDays = onlyShowOfficeDays
        this.dietaryClasses = dietaryClasses
    }

    /**
     *
     * @returns a new {@link FeedFilter} instance with all categories unselected
     */
    public uncheckAllCategories(): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()
        newFeedFilter.categories.forEach((category) => category.isSelected = false)
        return newFeedFilter
    }

    /**
     *
     * @returns a new {@link FeedFilter} instance with all restaurants unselected
     */
    public uncheckAllRestaurants(): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()
        newFeedFilter.restaurants.forEach((restaurant) => restaurant.isSelected = false)
        return newFeedFilter
    }

    /**
     *
     * @returns a new {@link FeedFilter} instance with the onlyShowOfficeDays unselected
     */
    public uncheckOnlyShowOfficeDays(): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()
        this.onlyShowOfficeDays = false
        return newFeedFilter
    }

    public uncheckAllDietaryClasses(): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()
        newFeedFilter.dietaryClasses.forEach((dietaryClass) => dietaryClass.isSelected = false)
        return newFeedFilter
    }

    /**
     *
     * @returns a new {@link FeedFilter} instance with all filters unselected
     */
    public uncheckAll(): FeedFilter {
        return this
            .createNewFeedFilter()
            .uncheckAllRestaurants()
            .uncheckAllCategories()
            .uncheckOnlyShowOfficeDays()
            .uncheckAllDietaryClasses()
    }

    /**
     *
     * @returns the current isApplied value
     */
    public isFilterApplied(): boolean {
        return this.isApplied
    }

    /**
     *
     * @param isApplied the new isApplied value
     * @returns a new {@link FeedFilter} instance with the new isApplied value
     */
    public changeIsApplied(isApplied: boolean): FeedFilter {
        return this.createNewFeedFilter(isApplied)
    }

    /**
     *
     * @param onlyShowOfficeDays the new onlyShowOfficeDays value
     * @returns a new {@link FeedFilter} instance with the new onlyShowOfficeDays value
     */
    public changeOnlyShowOfficeDays(onlyShowOfficeDays: boolean): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()

        newFeedFilter.onlyShowOfficeDays = onlyShowOfficeDays

        return newFeedFilter
    }

    /**
     *
     * @param dietaryClass the key used for searching the dietaryClasses
     * @param isSelected the new isSelected value for the given dietaryClass
     * @returns a new {@link FeedFilter} instance with the new dietaryClass value
     */
    public changeDietaryClass(dietaryClass: string, isSelected: boolean): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()

        newFeedFilter.dietaryClasses.forEach((dietaryClassFilter) => {
            if (dietaryClassFilter.dietaryClass.key === dietaryClass)
                return dietaryClassFilter.isSelected = isSelected
        })

        return newFeedFilter
    }

    /**
     *
     * @param categoryId The category to change the selection
     * @param isSelected The new isSelected value for the given category
     * @returns a new {@link FeedFilter} instance with the category selection changed
     */
    public changeProductCategorySelection(categoryId: string, isSelected: boolean): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()
        const categoryToChange = newFeedFilter.categories.find(({ category }) => category.id === categoryId)
        if (! categoryToChange) {
            Bugsnag.notify(`Category ${categoryId} not found in the filter state`)
            return newFeedFilter
        }
        categoryToChange.isSelected = isSelected
        return newFeedFilter
    }

    /**
     *
     * @param restaurantId The restaurant to change the selection
     * @param isSelected The new isSelected value for the given restaurant
     * @returns a new {@link FeedFilter} instance with the restaurant selection changed
     */
    public changeRestaurantSelection(restaurantId: string, isSelected: boolean): FeedFilter {
        const newFeedFilter = this.createNewFeedFilter()
        const restaurantToChange = newFeedFilter.restaurants.find(({ restaurant }) => restaurant.id === restaurantId)
        if (! restaurantToChange) {
            Bugsnag.notify(`Restaurant ${restaurantId} not found in the filter state`)
            return newFeedFilter
        }
        restaurantToChange.isSelected = isSelected
        return newFeedFilter
    }

    /**
     *
     * @returns a {@link MenuFeedFilterInput} object that can be used in the menu feed query
     */
    public toMenuFeedFilterInput(): MenuFeedFilterInput {
        const input: Mutable<MenuFeedFilterInput> = {
            productTypes: [ProductTypeEnum.Meal],
        }

        const restaurantsToFilter = this.isRestaurantsFiltered ?
            this.restaurants.filter(prop('isSelected')) : this.restaurants

        const addLocationFilter = restaurantsToFilter.some(
            ({ restaurant }) => restaurant.type !== RestaurantTypeEnum.Delivery,
        )
        // If some restaurant is not delivery we add the location filter
        if (addLocationFilter) input.locations = [this.locationId]

        input.dietaryClasses = this.dietaryClasses
            .filter(prop('isSelected'))
            .map((dietaryClassFilter) => dietaryClassFilter.dietaryClass.key)

        input.restaurants = restaurantsToFilter.map(pipe(prop('restaurant'), prop('id')))
        const productCategories = this.isCategoriesFiltered ?
            filterMap(this.categories, prop('isSelected'), pipe(prop('category'), prop('id')))
            : []
        input.productCategories = productCategories.length > 0 ? productCategories : undefined

        return input
    }

    /**
     *
     * @returns the selected {@link RestaurantFragment}
     * @remarks if {@link isRestaurantsFiltered} is false it will return all the restaurants
     */
    public toRestaurantsSelected(): RestaurantFragment[] {
        return this.isRestaurantsFiltered ?
            filterMap(this.restaurants, prop('isSelected'), prop('restaurant'))
            : this.restaurants.map(prop('restaurant'))
    }

    /**
     *
     * @param isApplied send param if you want to change the isApplied value for the new {@link FeedFilter}
     * @returns a copy of the current instance with the new isApplied value if provided,
     * otherwise the same isApplied value
     */
    private createNewFeedFilter(isApplied?: boolean): FeedFilter {
        return new FeedFilter(
            this.locationId,
            isApplied ?? this.isApplied,
            clone(this.restaurants),
            clone(this.categories),
            clone(this.onlyShowOfficeDays),
            clone(this.dietaryClasses),
        )
    }
}
