import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-underline-button',
    templateUrl: './underline-button.component.html',
    styleUrls: ['./underline-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderlineButtonComponent {
    @Input()
    public text: string

    @Output()
    public readonly clicked = new EventEmitter<Event>()
}
