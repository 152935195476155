import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { ThemingService } from '@app/services/theming/theming.service'
import { BehaviorSubject, combineLatest, ReplaySubject, SubscriptionLike } from 'rxjs'
import { ContextualColorsMap } from '@app/services/theming/theming.types'


@Directive({ selector: '[appContextualColor]' })
export class ContextualColorDirective implements OnInit, OnDestroy {

    @Input()
    public set contextualTarget(target: 'color' | 'backgroundColor' | '--ion-color-base' | string) {
        this.colorTarget$.next(target)
    }

    @Input()
    public set appContextualColor(name: string) {
        this.colorName$.next(name)
    }

    private colorTarget$ = new BehaviorSubject<string>('--ion-color-base')
    private colorName$ = new ReplaySubject<string>()
    private sub?: SubscriptionLike

    constructor(
        private readonly el: ElementRef,
        private readonly themingService: ThemingService,
    ) {
    }

    public ngOnInit(): void {
        this.sub = combineLatest([
            this.colorName$,
            this.colorTarget$,
            this.themingService.contextualColors$,
        ]).subscribe(([name, target, colorMap]) => this.handleThemeChange(name, target, colorMap))
    }

    public ngOnDestroy(): void {
        this.sub?.unsubscribe()
    }

    private handleThemeChange(name: string, target: string, colorMap: ContextualColorsMap): void {
        const nativeElement: HTMLElement = this.el.nativeElement

        nativeElement.style.backgroundColor = ''
        nativeElement.style.color = ''

        if (name in colorMap) {
            if (target.startsWith('--')) {
                nativeElement.style.setProperty(target, colorMap[name]!, 'important')
            } else {
                nativeElement.style[target as any] = `${colorMap[name]} !important`
            }
        }
    }
}
