import { AbstractDeepLink, DeeplinkHandlerArgs } from '@app/services/deeplinks/links/AbstractDeepLink'

export class CheckoutKickbackDeeplink extends AbstractDeepLink {
    public readonly pathPattern: string = '/checkout/kickback/:paymentId'
    public readonly alias: string = 'checkoutKickback'

    public async handler({ $event, router }: DeeplinkHandlerArgs): Promise<void> {
        const { paymentId } = $event.$args
        await router.navigateByUrl(`/checkout/kickback/${paymentId}`)
    }
}
