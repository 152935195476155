import { Inject, Injectable } from '@angular/core'
import {
    ClientResolutionStrategyEnum,
    FinishPkceLoginMutationService,
    StartPkceLoginMutation,
    StartPkceLoginMutationService,
} from '@app-graphql/api-schema'
import { PKCE_REDIRECTOR, PkceRedirector } from '@app/domains/pkce/providers/pkce-redirector.provider'
import { PKCE_CALLBACK_URI } from '@app/domains/pkce/providers/pkce-callback-url.provider'
import { PkcePair } from '@app/domains/pkce/lib/pkce.lib'
import { firstValueFrom } from 'rxjs'
import { isNil } from 'ramda'
import { PkceInitializationError } from '@app/domains/pkce/errors/PkceInitializationError'
import { Storage } from '@ionic/storage'
import { PkceCallbackError } from '@app/domains/pkce/errors/PkceCallbackError'
import { AuthStorageService } from '@app/services/auth-storage/auth-storage.service'
import { authPayloadToAuthState, isValidAuthPayload } from '@lib/auth/auth.lib'
import { AuthService } from '@app/services/auth/auth.service'
import { MutationResult } from 'apollo-angular'
import { errorMessage } from '@lib/common.lib'

export interface StartPkceLoginParams {
    emailAddress?: string
    clientShortcode?: string
}

export enum ContinuationFlow {
    PkceLogin = 'PKCE_LOGIN',
    LocalLogin = 'LOCAL_LOGIN',
    Register = 'REGISTER',
}

export namespace StartPkceLoginResult {
    export interface PkceLogin {
        continuation: ContinuationFlow.PkceLogin
        /**
         * A function that can be called – akin to a command – whenever the app is ready to redirect the user
         * to the external PKCE login page. Does not use any arguments.
         */
        pkceRedirectInitiator: () => void
    }

    export interface LocalLogin {
        continuation: ContinuationFlow.LocalLogin
    }

    export interface Registration {
        continuation: ContinuationFlow.Register
    }

    export type Any = PkceLogin | LocalLogin | Registration
}

@Injectable()
export class PkceLoginService {
    constructor(
        @Inject(PKCE_REDIRECTOR)
        private readonly pkceRedirector: PkceRedirector,
        @Inject(PKCE_CALLBACK_URI)
        private readonly pkceCallbackUri: string,
        private readonly storage: Storage,
        private readonly authService: AuthService,
        private readonly authStorageService: AuthStorageService,
        private readonly startPkceLoginMutationService: StartPkceLoginMutationService,
        private readonly finishPkceLoginMutationService: FinishPkceLoginMutationService,
    ) {
    }

    /**
     * Attempts to initiate a PKCE login for the given email address and optional client shortcode.
     * The shortcode can be given to force the login to be attempted against a specific client's
     * identity server.
     *
     * This method returns a promise of {@link StartPkceLoginResult.Any} which has one of three shapes depending
     * on the registration status of the given email address.
     *
     * - When `hasAccount = false`, a login procedure cannot be started in any way because the server cannot
     * decide that the email address exists.
     * - When `hasAccount = true` but `usePkceLogin = false`, a local login (password grant) can be started
     * - When `hasAccount = true` and `usePkceLogin = true`, a PKCE redirect can be initiated by calling the
     * `pkceRedirectInitiator` function.
     */
    public async start(params: StartPkceLoginParams): Promise<StartPkceLoginResult.Any> {
        await this.removePkcePair()
        const pkcePair = await PkcePair.new()

        let result: MutationResult<StartPkceLoginMutation>

        try {
            result = await firstValueFrom(
                this.startPkceLoginMutationService.mutate({
                    emailAddress: params.emailAddress,
                    shortcode: params.clientShortcode,
                    codeChallenge: pkcePair.getChallenge(),
                    redirectUri: this.pkceCallbackUri,
                    fallbackRedirectUri: '/',
                }),
            )
        } catch (error: any) {
            if (error?.message === 'Could not determine client from provided arguments.') {
                // Note: this is a bit hacky, but unfortunately the API currently doesn't have a non-error
                // execution path for the case where the email does not yet have an account and also cannot
                // be matched with a registered domain. This statement should hit for all generic email
                // addresses without an existing account.
                return {
                    continuation: ContinuationFlow.Register,
                }
            }

            throw new PkceInitializationError(errorMessage(error))
        }

        if (isNil(result.data)) {
            throw new PkceInitializationError('Unexpected response from server: missing data.')
        }

        const { redirectUri, isFallbackRedirectUri, clientResolutionStrategy } = result.data.startPkceLogin

        switch (clientResolutionStrategy) {

            case ClientResolutionStrategyEnum.Email: {
                // If the client was resolved by exact email address that means that we have an account.

                if (isFallbackRedirectUri) {
                    // If the response also gave the fallback redirect URL, that means we need the account
                    // to login locally with password grant.
                    return {
                        continuation: ContinuationFlow.LocalLogin,
                    }
                } else {
                    // Otherwise we give back the PKCE login redirect signal:
                    await this.storePkcePair(pkcePair)
                    return {
                        continuation: ContinuationFlow.PkceLogin,
                        pkceRedirectInitiator: () => this.pkceRedirector.handleRedirectUrl(redirectUri),
                    }
                }
            }

            case ClientResolutionStrategyEnum.Domain:
            case ClientResolutionStrategyEnum.Shortcode: {
                // If the client as resolved by either the domain part of the email or a client registration
                // shortcode, it means we have no account by the given email yet.
                if (isFallbackRedirectUri) {
                    // If so, and we have no PKCE redirect URL, we'll give the local registration signal.
                    // Calling code should then redirect the user to the local registration page.
                    return {
                        continuation: ContinuationFlow.Register,
                    }
                } else {
                    // Otherwise we can give the PKCE redirect signal. After logging in externally the user
                    // will be redirected back to our API, which will then create a new account as necessary.
                    await this.storePkcePair(pkcePair)
                    return {
                        continuation: ContinuationFlow.PkceLogin,
                        pkceRedirectInitiator: () => this.pkceRedirector.handleRedirectUrl(redirectUri),
                    }
                }
            }
        }
    }

    /**
     * Handles a PKCE callback redirection. Takes the exchange token that is part of the callback request
     * and attempts to verify the login with the API using the internally stored code verifier. When it
     * returns without error, the app is in a state ready to redirect the user to the authentication-guarded
     * pages. A valid access token and the user's information will both be available.
     */
    public async finish(exchangeToken: string, policiesAccepted: boolean): Promise<void> {
        const codePair = await this.fetchPkcePair()

        const result = await firstValueFrom(
            this.finishPkceLoginMutationService.mutate({
                exchangeToken,
                policiesAccepted,
                codeVerifier: codePair.getVerifier(),
            }),
        )

        if (isNil(result.data)) {
            throw new PkceCallbackError('Failed to finish PKCE login with the API. Got null response data')
        }

        const payload = result.data.finishPkceLogin

        if (! isValidAuthPayload(payload)) {
            throw new PkceCallbackError('Got invalid Auth payload from PKCE verification request.')
        }

        const state = authPayloadToAuthState(payload)

        await this.authStorageService.submitAuthState(state)
        await this.authService.refreshUser()
        await this.removePkcePair()
    }

    // ------------------------------------------------------------------------------
    //      PKCE code pair storage methods
    // ------------------------------------------------------------------------------

    private async storePkcePair(pair: PkcePair): Promise<void> {
        await this.storage.set('PKCE-pair', pair.toScalar())
    }

    private async fetchPkcePair(): Promise<PkcePair> {
        return PkcePair.fromScalar(
            await this.storage.get('PKCE-pair'),
        )
    }

    private async removePkcePair(): Promise<void> {
        await this.storage.remove('PKCE-pair')
    }
}
