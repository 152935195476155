import { StorageConfig } from '@ionic/storage-angular'
import { Drivers } from '@ionic/storage'

export const ionicStorageModuleConfig: StorageConfig = {
    name: 'settings',
    driverOrder: [
        Drivers.SecureStorage,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
    ],
}
