import { add as addDuration } from 'date-fns'
import { now } from '@lib/date-time/date-time.lib'

export interface AuthPayload {
    access_token: string
    expires_in: number
    refresh_token: string
    token_type: string
}

export interface AuthState {
    readonly accessToken: string
    readonly tokenType: string
    readonly refreshToken: string

    /**
     * A date instance indicating when the current access token is expected to expire.
     * Note: This is different from, though derived from, the 'expires in' information
     * that comes from token API responses.
     */
    readonly expiresAt: Date
}


/**
 * Tests if the given value is the shape of a full authentication response payload, ie. if
 * it includes all auth information and tokens.
 */
export function isValidAuthPayload(payload: any): payload is AuthPayload {
    try {
        return typeof payload === 'object' && payload !== null
            && typeof payload.access_token === 'string'
            && typeof payload.refresh_token === 'string'
            && typeof payload.token_type === 'string'
            && typeof payload.expires_in === 'number'
    } catch (error: unknown) {
        return false
    }
}

/**
 * Tests if the given value is the shape of a full authentication state object, as is
 * locally stored on the app device.
 */
export function isValidAuthState(state: any): state is AuthState {
    try {
        return typeof state === 'object' && state !== null
            && typeof state.accessToken === 'string'
            && typeof state.tokenType === 'string'
            && typeof state.refreshToken === 'string'
            && state.expiresAt instanceof Date
    } catch (error: unknown) {
        return false
    }
}

/**
 * Converts the given auth response payload to an AuthState object.
 */
export function authPayloadToAuthState(payload: AuthPayload): AuthState {
    return {
        accessToken: payload.access_token,
        expiresAt: expiresInToExpiresAt(payload.expires_in),
        refreshToken: payload.refresh_token,
        tokenType: payload.token_type,
    }
}

// ------------------------------------------------------------------------------
//      Private helper functions
// ------------------------------------------------------------------------------

/**
 * Derives an 'expires at' date instance from the given 'expires in' amount in seconds.
 */
function expiresInToExpiresAt(expiresInSeconds: number): Date {
    return addDuration(now(), {
        seconds: expiresInSeconds,
    })
}
