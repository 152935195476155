import { Platform } from '@ionic/angular'
import { InjectionToken } from '@angular/core'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'

/**
 * Handles PKCE login redirects as appropriate for the current runtime environment.
 */
export interface PkceRedirector {
    /**
     * Handles PKCE login redirects as appropriate for the current runtime environment.
     * Returns void, since upon redirect the current code flow should terminate. In the
     * case of web-based runtimes the current window will redirect. In the case of
     * native environments the opened web page will redirect back into the app to the
     * pkce-callback route which should be a different route from the one where this
     * method is (directly or indirectly) called.
     */
    handleRedirectUrl(uri: string): void
}

/**
 * Injection token for a PKCE redirect strategy. It resolves an object with one method
 * "handleRedirectUrl" which initiates the external login redirect. Depending on the
 * runtime environment (web vs. native) different steps are required to do this.
 */
export const PKCE_REDIRECTOR = new InjectionToken<PkceRedirector>('PKCE_REDIRECTOR')

/**
 * Creates a value that can be provided for the {@link PKCE_REDIRECTOR} injection token.
 */
export function pkceRedirectorFactory(platform: Platform, inAppBrowser: InAppBrowser): PkceRedirector {

    // For web-based environments (development or web-based acceptance deployments) we'll simply
    // redirect to the external login page in the current browser tab.
    if (platform.is('desktop') || platform.is('mobileweb')) {
        return {
            handleRedirectUrl(uri) {
                window.location.href = uri
            },
        }
    }

    // For native environments (iOS and Android apps) we'll open an in-app browser window that
    // handles the external login for us. It will on success be redirected to our API and from
    // there back into the current app process.
    return {
        handleRedirectUrl(uri) {
            inAppBrowser.create(uri, '_system', {
                hideurlbar: 'yes',
                hidenavigationbuttons: 'no',
            })
        },
    }
}
