import { GraphQLError } from 'graphql'
import { values } from 'ramda'

export class RegistrationError extends Error {
    constructor(public readonly previous: GraphQLError) {
        super(previous.message)
    }

    public isValidationError(): boolean {
        return this.previous.extensions?.category === 'validation'
    }

    public getValidationMessage(): string | undefined {
        return values(this.previous.extensions?.validation as any)?.[0]?.[0]
    }
}
