<ion-checkbox
    color="primary"
    labelPlacement="end"
    justify="start"
    aria-labelledby="consent-checkbox-label"
    [formControl]="control"
/>

<div class="checkbox-label" id="consent-checkbox-label">
    {{ 'consent-checkbox.express-consent' | translate }}

    <br>

    <app-underline-button
        [text]="'consent-checkbox.terms-of-use' | translate"
        (clicked)="openTermsOfUse()"
    />

    {{ 'common.and' | translate }}

    <app-underline-button
        [text]="'consent-checkbox.privacy-policy' | translate"
        (clicked)="openPrivacyPolicy()"
    />
</div>
