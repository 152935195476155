<app-auth-template
    [withCountrySelectionLink]="false"
>

    <ng-container *ngIf="! isLoading; else loading">
        <app-user-consent-checkbox
            [control]="form.controls.consent"
        />

        <ion-button
            class="submit-button"
            [disabled]="!form.valid"
            (click)="finishConsent()"
        >
            {{ 'auth.continue' | translate }}
        </ion-button>
    </ng-container>

    <ng-template #loading>
        <ion-progress-bar type="indeterminate"/>
    </ng-template>
</app-auth-template>
