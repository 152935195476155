/**
 * The function that always returns true.
 */
export const TRUE = (): true => true

/**
 * The function that always returns false.
 */
export const FALSE = (): false => false

/**
 * The function that does nothing and returns nothing.
 */
export const noop = () => undefined
